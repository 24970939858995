import { unscrambleImage } from "./unscrambler.js";
import { saveImageToIndexedDB, clearIndexedDB } from "./DBUtils.js";
import { setupDragListeners } from "./DragUtility.js";
import { setupDropListeners } from "./DropUtility.js";
import { checkForQRCode } from "./QRCodeChecker.js";

export function setupAppDownloadOverlay() {
    const appOverlay = document.getElementById("app-overlay");
    const closePopupButton = document.getElementById("close-popup");
    const downloadAppButton = document.getElementById("download-app");

    if (appOverlay) {
        // Show the app overlay
        appOverlay.style.display = "flex";
        console.log("App overlay displayed");

        // Handle "No Thanks" button
        if (closePopupButton) {
            closePopupButton.addEventListener("click", () => {
                appOverlay.style.display = "none";
                console.log("App overlay closed");
            });
        } else {
            console.error("Close popup button not found");
        }

        // Handle "Download App" button
        if (downloadAppButton) {
            downloadAppButton.addEventListener("click", () => {
                const appStoreURL = "https://apps.apple.com/app/id6738210207";
                window.location.href = appStoreURL; // Redirect to App Store
                console.log("Redirecting to App Store");
            });
        } else {
            console.error("Download App button not found");
        }
    } else {
        console.error("App overlay not found");
    }
}



export function setupLandingPage() {
    console.log('Landing Page Initialized version 3.28');

    const blingSound = document.getElementById('bling-sound');

    // Ensure the App Download Overlay is set up
    setupAppDownloadOverlay();

    // Setup event listener for the "Unscramble a Pique" button
    const unscrambleButton = document.getElementById('unscramble-button');
    if (unscrambleButton) {
        unscrambleButton.addEventListener('click', () => {
            if (blingSound) {
                blingSound.play().catch((error) => {
                    console.error('Error playing bling sound:', error);
                });
            }

            const fileSelector = document.getElementById('file-selector');
            if (fileSelector) {
                fileSelector.click(); // Trigger the file input dialog
            } else {
                console.error('File selector not found');
            }
        });
    } else {
        console.error('Unscramble button not found');
    }

    setEvenDimensions("puzzle-container");

    if (typeof sessionStorage !== 'undefined') {
        sessionStorage.clear();
    } else {
        console.warn('Session storage is not supported in this browser or mode.');
    }

    clearIndexedDB('images');
    setupDragListeners();
    setupDropListeners();

    // Add event listener for file selection
    const fileSelector = document.getElementById('file-selector');
    if (fileSelector) {
        fileSelector.addEventListener('change', handleFileSelection);
    }
}

document.addEventListener('DOMContentLoaded', () => {
    const createPiqueButton = document.getElementById('create-pique-button');
    if (createPiqueButton) {
        createPiqueButton.addEventListener('click', () => {
            try {
                const newWindow = window.open('https://apps.apple.com/app/6738210207', '_blank');
                if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
                    // Fallback if popup is blocked
                    window.location.href = 'https://apps.apple.com/app/6738210207';
                }
            } catch (error) {
                console.error('Error opening new window:', error);
                window.location.href = 'https://apps.apple.com/app/6738210207';
            }
        });
    } else {
        console.error('Create Pique button not found');
    }
});

document.addEventListener('DOMContentLoaded', () => {
    const welcomeVideo = document.getElementById('welcome-video');

    if (welcomeVideo) {
        // Pause the video when it ends
        welcomeVideo.addEventListener('ended', () => {
            welcomeVideo.pause();
        });
    }
});

document.addEventListener('DOMContentLoaded', () => {
    const promoVideo = document.getElementById('promo-video');

    if (promoVideo) {
        console.log('Promo video controls enabled:', promoVideo.controls);
        // Ensure controls are explicitly enabled
        promoVideo.controls = true;
        promoVideo.setAttribute('playsinline', 'true');
        promoVideo.setAttribute('webkit-playsinline', 'true');
    }
});



// Handles the file selection and QR code validation
function handleFileSelection(event) {
    const file = event.target.files[0];
    const blingSound = document.getElementById('bling-sound');

    if (!file) return;

    const reader = new FileReader();
    reader.onload = function(event) {
        const imageSrc = event.target.result;

        // Check for QR code in the selected image
        checkForQRCode(imageSrc, (isValidQRCode) => {
            if (!isValidQRCode) {
                showOverlay(blingSound); // Show overlay for invalid QR code
                return; // Exit if the QR code is invalid
            }

            // Proceed if QR code is valid
            const img = new Image();
            img.onload = function() {
                saveImageToIndexedDB('images', { id: 'originalImage', originalImageSrc: imageSrc })
                    .then(() => {
                        unscrambleImage(10); // Call unscrambleImage with gridSize 10
                        completeLandingSetup(); // Hide the landing content
                    })
                    .catch((error) => {
                        console.error('Error saving image:', error);
                    });
            };
            img.src = imageSrc;
        });
    };
    reader.readAsDataURL(file);
}

// Creates and displays the overlay for invalid QR code
function showOverlay(blingSound) {
    // Check if an overlay already exists
    if (document.getElementById('error-overlay')) {
        console.warn('Overlay already exists. Skipping creation.');
        return;
    }

    // Create the overlay
    const overlay = document.createElement('div');
    overlay.id = 'error-overlay';
    overlay.style.position = 'fixed';
    overlay.style.top = '0';
    overlay.style.left = '0';
    overlay.style.width = '100%';
    overlay.style.height = '100%';
    overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
    overlay.style.zIndex = '10000';
    overlay.style.display = 'flex';
    overlay.style.flexDirection = 'column';
    overlay.style.justifyContent = 'center';
    overlay.style.alignItems = 'center';
    document.body.appendChild(overlay);

    // Create the warning message
    const warningMessage = document.createElement('div');
    warningMessage.innerText = "I'm sorry, this doesn't seem to be a Pique Pic. Please select another image.";
    warningMessage.style.color = 'white';
    warningMessage.style.textAlign = 'center';
    warningMessage.style.marginBottom = '20px';
    overlay.appendChild(warningMessage);

    // Create the "OK" button
    const okButton = document.createElement('button');
    okButton.innerText = "OK";
    okButton.style.padding = '10px 20px';
    okButton.style.cursor = 'pointer';
    okButton.onclick = () => {
        if (blingSound) {
            blingSound.play().catch((error) => {
                console.error('Error playing bling sound:', error);
            });
        }
        document.body.removeChild(overlay); // Remove the overlay
    };
    overlay.appendChild(okButton);
}

export function completeLandingSetup() {
    const landingContent = document.getElementById('landing-content');
    const existingOverlay = document.getElementById('error-overlay');

    if (landingContent) {
        landingContent.style.display = 'none'; // Hide the landing content
    } else {
        console.error('Landing content not found');
    }

    if (existingOverlay) {
        document.body.removeChild(existingOverlay); // Clean up overlay if it exists
    }
}

export function setEvenDimensions(elementId) {
    const container = document.getElementById(elementId);
    if (container) {
        const computedWidth = container.offsetWidth;
        const computedHeight = container.offsetHeight;

        const evenWidth = Math.round(computedWidth / 2) * 2;
        const evenHeight = Math.round(computedHeight / 2) * 2;

        container.style.width = `${evenWidth}px`;
        container.style.height = `${evenHeight}px`;
    }
}

