import { initializeGame } from './TrianglePuzzle.js';
import { initializeCircleGame } from './CirclePuzzle.js';
import { initializeSquarePuzzle } from './SquarePuzzle.js';

let difficulty;

export function initializeMenu(unscrambledImageURL) {
    const blingSound = document.getElementById('bling-sound');

    // Get containers
    const puzzleContainer = document.getElementById('puzzle-container');
    const pieceSpace = document.getElementById('piece-space');

    // Create the carousel container for images or videos
    const carouselContainer = document.createElement('div');
    carouselContainer.style.width = '100%';
    carouselContainer.style.height = '100%';
    carouselContainer.style.display = 'flex';
    carouselContainer.style.alignItems = 'center';
    carouselContainer.style.justifyContent = 'center';
    carouselContainer.style.position = 'relative'; // Make the carousel relative
    carouselContainer.style.zIndex = '10'; // Ensure the carousel is on top
    puzzleContainer.appendChild(carouselContainer); // Append to puzzle container

    // Create puzzle elements (use videos if available, fall back to images)
    const trianglePuzzleElement = createPuzzleElement(
        './images/triangle-puzzle.jpg',
        'Triangle Puzzle',
        './images/MEDIUM.mp4'
    );
    const circlePuzzleElement = createPuzzleElement(
        './images/circle-puzzle.jpg',
        'Circle Puzzle',
        './images/EASY.mp4'
    );
    const squarePuzzleElement = createPuzzleElement(
        './images/square-puzzle.jpg',
        'Slider Puzzle',
        './images/DIFFICULT.mp4'
    );

    // Create the arrow buttons
    const leftArrow = document.createElement('button');
    leftArrow.textContent = '←';
    leftArrow.classList.add('arrow-button'); // Add the class for styling

    const rightArrow = document.createElement('button');
    rightArrow.textContent = '→';
    rightArrow.classList.add('arrow-button'); // Add the class for styling

    // Create the "CHOOSE A GAME" text
    const chooseText = document.createElement('div');
    chooseText.textContent = 'CHOOSE A GAME';
    chooseText.classList.add('choose-game-text'); // Add the class for styling

    // Append elements to the piece space
    pieceSpace.appendChild(leftArrow);
    pieceSpace.appendChild(chooseText);
    pieceSpace.appendChild(rightArrow);

    // Append the first puzzle element to the carousel container
    carouselContainer.appendChild(circlePuzzleElement); // Initially display the circle puzzle

    // Puzzle options for the carousel
    const puzzleOptions = [circlePuzzleElement, trianglePuzzleElement, squarePuzzleElement];
    let currentIndex = 0;

    function playBlingSound() {
        if (blingSound) {
            blingSound.play().catch((error) => {
                console.error('Error playing bling sound:', error);
            });
        }
    }

    // Update displayed puzzle based on current index
    function updateDisplayedPuzzle() {
        const currentPuzzle = puzzleOptions[currentIndex];
        carouselContainer.replaceChild(currentPuzzle, carouselContainer.children[0]);
    }

    // Left arrow event - moves backward, wraps to the end if at the start
    leftArrow.addEventListener('click', () => {
        playBlingSound(); // Play sound
        currentIndex = (currentIndex - 1 + puzzleOptions.length) % puzzleOptions.length;
        updateDisplayedPuzzle();
    });

    // Right arrow event - moves forward, wraps to the beginning if at the end
    rightArrow.addEventListener('click', () => {
        playBlingSound(); // Play sound
        currentIndex = (currentIndex + 1) % puzzleOptions.length;
        updateDisplayedPuzzle();
    });

    // Event listeners for starting the selected game
    trianglePuzzleElement.addEventListener('click', () => {
        playBlingSound();
        difficulty = "Medium"; // Set difficulty directly
        cleanupMenu();
        initializeGame(unscrambledImageURL);
    });

    circlePuzzleElement.addEventListener('click', () => {
        playBlingSound();
        difficulty = "Easy"; // Set difficulty directly
        cleanupMenu();
        initializeCircleGame(unscrambledImageURL);
    });

    squarePuzzleElement.addEventListener('click', () => {
        playBlingSound();
        difficulty = "Hard"; // Set difficulty directly
        cleanupMenu();
        initializeSquarePuzzle();
    });

    // Helper function to create puzzle elements (video or image)
    function createPuzzleElement(imageSrc, altText, videoSrc) {
        const container = document.createElement('div');
        container.style.position = 'relative';
        container.style.width = '100%';
        container.style.height = '100%';
        container.style.cursor = 'pointer';

        if (videoSrc) {
            const video = document.createElement('video');
            video.src = videoSrc;
            video.autoplay = true;
            video.loop = true;
            video.muted = true;
            video.playsInline = true;
            video.style.width = '100%';
            video.style.height = '100%';
            video.style.objectFit = 'cover';
            video.style.borderRadius = '20px';
            video.classList.add('fade-edges');
            container.appendChild(video);
        } else {
            const img = document.createElement('img');
            img.src = imageSrc;
            img.alt = altText;
            img.style.width = '100%';
            img.style.height = '100%';
            img.style.borderRadius = '20px';
            img.style.objectFit = 'cover';
            img.classList.add('fade-edges');
            container.appendChild(img);
        }

        return container;
    }

    // Helper function to clean up the menu UI
    function cleanupMenu() {
        puzzleContainer.removeChild(carouselContainer);
        pieceSpace.removeChild(leftArrow);
        pieceSpace.removeChild(chooseText);
        pieceSpace.removeChild(rightArrow);
    }
}

export { difficulty };