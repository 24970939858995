import { setupRingDragListeners } from "./RingDragListeners.js";
import { openDatabase } from './DBUtils.js';
import { displayReadySetGo } from "./Demo.js";

export async function initializeCircleGame(imageSrc) {
    console.log ('Initializing CirclePuzzle version 16');

    openDatabase(async () => {
        const puzzleContainer = document.getElementById('puzzle-container');
        if (!puzzleContainer) {
            console.error('Puzzle container not found');
            return;
        }

        // Disable right-click context menu on the puzzle container
        puzzleContainer.addEventListener('contextmenu', (event) => {
            event.preventDefault();
        });


        const image = new Image();
        image.src = imageSrc;

        image.onload = async () => {
            console.log('Image loaded successfully');

            const canvasSize = Math.min(puzzleContainer.offsetWidth, puzzleContainer.offsetHeight);

            // Set up the background layer with the original image
            const backgroundLayer = document.createElement('div');
            backgroundLayer.style.position = 'absolute';
            backgroundLayer.style.top = '0';
            backgroundLayer.style.left = '0';
            backgroundLayer.style.width = `${canvasSize}px`;
            backgroundLayer.style.height = `${canvasSize}px`;
            backgroundLayer.style.backgroundImage = `url(${imageSrc})`;
            backgroundLayer.style.backgroundSize = 'cover';
            backgroundLayer.style.zIndex = '0';
            puzzleContainer.appendChild(backgroundLayer);
            
              

            const canvas = document.createElement('canvas');
            console.log('Canvas created:', canvas);
            console.log('Canvas size:', canvas.width, canvas.height);  
            canvas.width = canvasSize;
            canvas.height = canvasSize;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
            puzzleContainer.appendChild(canvas);

            const centerX = canvas.width / 2;
            const centerY = canvas.height / 2;
            const ringCount = 5;
            const ringWidth = (canvas.width / 2) / ringCount;

            // Crop and stack rings with correct z-index layering, and apply random rotation
            for (let i = 0; i < ringCount; i++) {
                const outerRadius = (i + 1) * ringWidth;
                const innerRadius = i * ringWidth;

                // Create and position the ring
                const ringCanvas = cropRing(canvas, ctx, centerX, centerY, innerRadius, outerRadius, ringCount - i);

                // Apply a random initial rotation to scramble the ring
                const randomRotation = Math.floor(Math.random() * 36) * 10;
                ringCanvas.style.transform = `translate(-50%, -50%) rotate(${randomRotation}deg)`;
                ringCanvas.dataset.currentRotation = randomRotation;  // Store initial rotation
            }

            console.log('All rings created and scrambled');

            // Make rings interactive
            setupRingDragListeners();
            displayReadySetGo()
        };

        image.onerror = () => {
            console.error('Error loading image');
        };
    });
}

function cropRing(canvas, ctx, centerX, centerY, innerRadius, outerRadius, ringIndex) {
    // Create a new canvas for each ring
    const ringCanvas = document.createElement('canvas');
    ringCanvas.width = outerRadius * 2;
    ringCanvas.height = outerRadius * 2;
    const ringCtx = ringCanvas.getContext('2d');

    // Translate to center and clip to create a ring shape
    ringCtx.clearRect(0, 0, ringCanvas.width, ringCanvas.height);
    ringCtx.translate(outerRadius, outerRadius);
    ringCtx.beginPath();
    ringCtx.arc(0, 0, outerRadius, 0, 2 * Math.PI);
    ringCtx.arc(0, 0, innerRadius, 0, 2 * Math.PI, true);
    ringCtx.clip();

    // Draw the relevant portion of the image onto the ring
    ringCtx.drawImage(
        canvas,
        centerX - outerRadius,
        centerY - outerRadius,
        outerRadius * 2,
        outerRadius * 2,
        -outerRadius,
        -outerRadius,
        outerRadius * 2,
        outerRadius * 2
    );

    // Apply a dynamic clip-path based on the outer and inner radius
    const outerClipRadius = outerRadius;
    const innerClipRadius = innerRadius;
    ringCanvas.style.clipPath = `circle(${outerClipRadius}px at 50% 50%)`;

    // Position and style the ring canvas
    ringCanvas.classList.add('ring-shape');
    ringCanvas.style.position = 'absolute';
    ringCanvas.style.top = '50%';
    ringCanvas.style.left = '50%';
    ringCanvas.style.transform = 'translate(-50%, -50%)';
    ringCanvas.style.zIndex = `${1000 + ringIndex}`;

    ringCanvas.style.border = '4px solid purple';
    ringCanvas.style.borderRadius = '50%'; // Make the border circular

    // Add the circular ring to the puzzle container
    ringCanvas.classList.add(`ring-${ringIndex}`, 'ring');
    document.getElementById('puzzle-container').appendChild(ringCanvas);

    return ringCanvas; // Return the canvas element for further manipulation
}

function initializeRings(canvasSize, ringCount, imageSrc) {
    const puzzleContainer = document.getElementById('puzzle-container');

    // Set up a base image to use for the canvas drawing
    const image = new Image();
    image.src = imageSrc;

    image.onload = () => {
        const centerX = canvasSize / 2;
        const centerY = canvasSize / 2;
        const ringWidth = (canvasSize / 2) / ringCount;

        for (let i = 0; i < ringCount; i++) {
            const outerRadius = (i + 1) * ringWidth;
            const innerRadius = i * ringWidth;

            // Create and style canvas for each ring
            const ringCanvas = document.createElement('canvas');
            ringCanvas.width = canvasSize;
            ringCanvas.height = canvasSize;
            ringCanvas.style.position = 'absolute';
            ringCanvas.style.top = '50%';
            ringCanvas.style.left = '50%';
            ringCanvas.style.transform = 'translate(-50%, -50%)';
            ringCanvas.style.zIndex = `${1000 + ringCount - i}`;

            const ctx = ringCanvas.getContext('2d');

            // Draw the ring shape onto the canvas
            ctx.clearRect(0, 0, ringCanvas.width, ringCanvas.height);
            ctx.beginPath();
            ctx.arc(centerX, centerY, outerRadius, 0, Math.PI * 2);
            ctx.arc(centerX, centerY, innerRadius, 0, Math.PI * 2, true); // Cut inner circle
            ctx.clip();
            ctx.drawImage(image, 0, 0, canvasSize, canvasSize);

            // Apply dynamic clip-path for precise pointer events
            const clipPath = `circle(${outerRadius}px at 50% 50%)`;
            ringCanvas.style.clipPath = clipPath;

            puzzleContainer.appendChild(ringCanvas);

            // Optional: Set up drag listeners or rotation based on touch or click
            setupRingInteraction(ringCanvas, i);
        }
    };
}
