//import { triangleSnapVertices, vertexPositions, createNextTriangleInPieceSpace } from "./TrianglePuzzle.js";
//import { stopTimer, startTimer } from "./Timer.js";
//import { getImagesFromIndexedDB } from './DBUtils.js'; // Import for retrieving images from IndexedDB

// Function to create a triangle element
export function createTriangle(id, container, base, height) {
    const triangle = document.createElement('div');
    triangle.classList.add('triangle');
    triangle.id = id;
    triangle.draggable = true;

    // Create and add a label element inside the triangle for the tag
    const label = document.createElement('span');
    label.textContent = id;  // This will display the triangle ID
    label.classList.add('triangle-label');
    triangle.appendChild(label);

    // Set the width and height for the triangle
    triangle.style.width = `${base}px`;
    triangle.style.height = `${height}px`;

    container.appendChild(triangle);
}
export function positionTriangle(triangleId, targetX, targetY, rotation) {
    const triangle = document.getElementById(triangleId);
    const triangleWidth = triangle.offsetWidth;
    const triangleHeight = triangle.offsetHeight;

    // Calculate the primary vertex (point where the two short sides meet) based on the current rotation
    let xOffset, yOffset;

    switch (rotation) {
        case 0:
            xOffset = triangleWidth / 2;
            yOffset = 0;  // Vertex is at the bottom
            break;
        case 90:
            xOffset = triangleWidth;
            yOffset = triangleHeight / 2;  // Vertex is on the right side
            break;
        case 180:
            xOffset = triangleWidth / 2;
            yOffset = triangleHeight;  // Vertex is at the top
            break;
        case 270:
            xOffset = 0;
            yOffset = triangleHeight / 2;  // Vertex is on the left side
            break;
        default:
            xOffset = triangleWidth / 2;
            yOffset = 0;  // Default to 0 degrees
            break;
    }

    // Adjust the position so the primary vertex aligns with the target coordinates
    triangle.style.left = `${targetX - xOffset}px`;
    triangle.style.top = `${targetY - yOffset}px`;

    // Apply rotation
    triangle.style.transform = `rotate(${rotation}deg)`;

    // Display the primary vertex marker
    calculatePrimaryVertexPosition(triangle, triangleWidth, triangleHeight);
}
export function makeTriangleInteractive(triangleId) {
    const triangle = document.getElementById(triangleId);

    // Skip F2 and F5 for any interactive behavior
    //if (triangleId === 'F2' || triangleId === 'F5') {
   //     return;
    //}

    let lastTapTime = 0;

    // Add double-tap rotation (mark touchstart as passive)
    triangle.addEventListener('touchstart', (event) => {
        const currentTime = new Date().getTime();
        const tapGap = currentTime - lastTapTime;
        if (tapGap < 400 && tapGap > 0) {
            let currentRotation = parseInt(event.target.style.transform.replace(/[^0-9]/g, '')) || 0;
            currentRotation = (currentRotation + 90) % 360;
            event.target.style.transform = `rotate(${currentRotation}deg)`;
        }
        lastTapTime = currentTime;
    }, { passive: true });

    // Mouse click rotation
    triangle.addEventListener('click', (event) => {
        let currentRotation = parseInt(event.target.style.transform.replace(/[^0-9]/g, '')) || 0;
        currentRotation = (currentRotation + 90) % 360;
        event.target.style.transform = `rotate(${currentRotation}deg)`;
    });

    // Dragstart functionality (keep as non-passive)
    triangle.addEventListener('dragstart', (event) => {
        event.dataTransfer.setData('text/plain', triangleId);
        event.dataTransfer.effectAllowed = 'move';
    });
}
/*
export function makeRingInteractive(ringId) {
    const ring = document.getElementById(ringId);

    let lastTapTime = 0;

    // Double-tap rotation for mobile (rotate by 90 degrees)
    ring.addEventListener('touchstart', (event) => {
        const currentTime = new Date().getTime();
        const tapGap = currentTime - lastTapTime;
        if (tapGap < 400 && tapGap > 0) {
            let currentRotation = parseFloat(ring.style.transform.replace(/[^0-9]/g, '')) || 0;
            currentRotation = (currentRotation + 90) % 360;
            ring.style.transform = `translate(-50%, -50%) rotate(${currentRotation}deg)`;
            ring.dataset.currentRotation = currentRotation;  // Update current rotation
            checkPuzzleCompletion();  // Check if puzzle is complete after each rotation
        }
        lastTapTime = currentTime;
    }, { passive: true });

    // Click rotation for desktop (rotate by 90 degrees)
    ring.addEventListener('click', () => {
        let currentRotation = parseFloat(ring.style.transform.replace(/[^0-9]/g, '')) || 0;
        currentRotation = (currentRotation + 90) % 360;
        ring.style.transform = `translate(-50%, -50%) rotate(${currentRotation}deg)`;
        ring.dataset.currentRotation = currentRotation;  // Update current rotation
        checkPuzzleCompletion();  // Check if puzzle is complete after each rotation
    });
}

// Apply this function to each ring after creating them
export function setupRingInteractions() {
    const rings = document.querySelectorAll('.ring');
    rings.forEach((ring, index) => {
        makeRingInteractive(`ring-${index}`);
    });
}
*/
// Function to calculate the primary vertex of the triangle and display it
export function calculatePrimaryVertexPosition(triangle, base, height) {
    if (!triangle || typeof triangle.getBoundingClientRect !== 'function') {
        console.error('Invalid triangle element passed:', triangle);
        return { x: 0, y: 0 };  // Default value to prevent errors
    }

    const rect = triangle.getBoundingClientRect();
    const container = document.getElementById('puzzle-container');
    const containerRect = container.getBoundingClientRect();
    const rotation = parseInt(triangle.style.transform.replace(/[^0-9]/g, '')) || 0;

    // Calculate the primary vertex based on rotation and the base/height
    let primaryX, primaryY;

    switch (rotation) {
        case 0:
            primaryX = rect.left + base / 2 - containerRect.left;
            primaryY = (rect.bottom - height) - containerRect.top;
            break;
        case 90:
            primaryX = (rect.left + height) - containerRect.left;
            primaryY = (rect.top + base / 2) - containerRect.top;
            break;
        case 180:
            primaryX = (rect.left + base / 2) - containerRect.left;
            primaryY = (rect.top + height) - containerRect.top;
            break;
        case 270:
            primaryX = (rect.left) - containerRect.left;
            primaryY = (rect.top + base / 2) - containerRect.top;
            break;
        default:
            primaryX = (rect.left + base / 2) - containerRect.left;
            primaryY = (rect.top + height) - containerRect.top;
            break;
    }

    return { x: primaryX, y: primaryY };
}
