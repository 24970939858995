import { createTriangle, positionTriangle, makeTriangleInteractive } from './UI.js';
import { demo } from './Demo.js';
import { setupDragListeners } from './DragUtility.js';
import { splitImageIntoTriangles } from './ImageProcessor.js';
import { setupDropListeners } from './DropUtility.js';
import { getImagesFromIndexedDB, loadImageFromIndexedDB, openDatabase, loadFinalImageFromIndexedDB } from './DBUtils.js';
import { handlePuzzleComplete } from './PuzzleCompletion.js';

// Snap vertices with dynamic container-based positions
export const triangleSnapVertices = {
    'F2': { vertex: 'V4', rotation: 0, snapOffsetX: 0, snapOffsetY: 0 },
    'F5': { vertex: 'V5', rotation: 0, snapOffsetX: 0, snapOffsetY: 0 },
    'E1': { vertex: 'V4', rotation: 90, snapOffsetX: 0, snapOffsetY: 0 },
    'E3': { vertex: 'V4', rotation: 270, snapOffsetX: 0, snapOffsetY: 0 },
    'E4': { vertex: 'V5', rotation: 90, snapOffsetX: 0, snapOffsetY: 0 },
    'E6': { vertex: 'V5', rotation: 270, snapOffsetX: 0, snapOffsetY: 0 },
    'D2': { vertex: 'V4', rotation: 180, snapOffsetX: 0, snapOffsetY: 0 },
    'D5': { vertex: 'V5', rotation: 180, snapOffsetX: 0, snapOffsetY: 0 },
    'C2': { vertex: 'V9', rotation: 0, snapOffsetX: 0, snapOffsetY: 0 },
    'C5': { vertex: 'V10', rotation: 0, snapOffsetX: 0, snapOffsetY: 0 },
    'B1': { vertex: 'V9', rotation: 90, snapOffsetX: 0, snapOffsetY: 0 },
    'B3': { vertex: 'V9', rotation: 270, snapOffsetX: 0, snapOffsetY: 0 },
    'B4': { vertex: 'V10', rotation: 90, snapOffsetX: 0, snapOffsetY: 0 },
    'B6': { vertex: 'V10', rotation: 270, snapOffsetX: 0, snapOffsetY: 0 },
    'A2': { vertex: 'V9', rotation: 180, snapOffsetX: 0, snapOffsetY: 0 },
    'A5': { vertex: 'V10', rotation: 180, snapOffsetX: 0, snapOffsetY: 0 }
};

export function rotateBackgroundImage(imgSrc, rotationAngle, element) {
    const img = new Image();
    img.src = imgSrc;

    img.onload = function() {
        rotationAngle = rotationAngle % 360;

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (rotationAngle === 90 || rotationAngle === 270) {
            canvas.width = img.height;
            canvas.height = img.width;
        } else {
            canvas.width = img.width;
            canvas.height = img.height;
        }

        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate(rotationAngle * Math.PI / 180);
        ctx.drawImage(img, -img.width / 2, -img.height / 2);

        const rotatedImageSrc = canvas.toDataURL();
        element.style.backgroundImage = `url(${rotatedImageSrc})`;
        element.style.backgroundSize = 'contain';
        element.style.backgroundPosition = 'center';
        element.style.backgroundRepeat = 'no-repeat';
    };

    img.onerror = function() {
        console.error(`Failed to load image for rotation: ${rotationAngle} degrees.`);
    };
}

export let vertexPositions = {};

export function getVertexPositions(container) {

    const containerWidth = container.offsetWidth;
    const containerHeight = container.offsetHeight;

    return {
        V4: { x: containerWidth * 0.25 + 0.000, y: containerHeight * 0.75 - 0.000 },
        V5: { x: containerWidth * 0.75 + 0.000, y: containerHeight * 0.75 - 0.000 },
        V9: { x: containerWidth * 0.25 + 0.000, y: containerHeight * 0.25 - 0.000},
        V10: { x: containerWidth * 0.75 - 0.000, y: containerHeight * 0.25 - 0.000 }
    };

}

export async function initializeGame(imageSrc) {

    // Initialize database and only proceed once it's ready
    openDatabase(async () => {
        const puzzleContainer = document.getElementById('puzzle-container');
        if (!puzzleContainer) {
            console.error('Puzzle container not found');
            return;
        }

        vertexPositions = getVertexPositions(puzzleContainer);

        const image = new Image();
        image.src = imageSrc;

        image.onload = async () => {
    
            // Wait until all triangles are saved
            await splitImageIntoTriangles(puzzleContainer.offsetWidth, puzzleContainer.offsetHeight, image);
            // After triangles are saved, proceed with the next step
            createNextTriangleInPieceSpace();
        };

        image.onerror = () => {
            console.error('Error loading image');
        };
    });
}


const triangleGroups = {
    F: ['F2', 'F5'],
    E: ['E1', 'E3', 'E4', 'E6'],
    D: ['D2', 'D5'],
    C: ['C2', 'C5'],
    B: ['B1', 'B3', 'B4', 'B6'],
    A: ['A2', 'A5']
};

let usedTriangles = [];

function getNextRandomTriangle() {
    const currentGroup = Object.keys(triangleGroups).find(group => triangleGroups[group].some(t => !usedTriangles.includes(t)));
    if (!currentGroup) return null;

    const availableTriangles = triangleGroups[currentGroup].filter(id => !usedTriangles.includes(id));
    const randomTriangleId = availableTriangles[Math.floor(Math.random() * availableTriangles.length)];
    usedTriangles.push(randomTriangleId);
    return randomTriangleId;
}

export function createNextTriangleInPieceSpace() {
    const nextTriangleId = getNextRandomTriangle();
    const puzzleContainer = document.getElementById('puzzle-container');

    if (!nextTriangleId) {
        // Clear the puzzle container when the puzzle is complete
        handlePuzzleComplete();
        return;
    }

    const snapData = triangleSnapVertices[nextTriangleId];
    const trueRotation = snapData.rotation;

    const pieceSpace = document.getElementById('piece-space');
    const pieceSpaceRect = pieceSpace.getBoundingClientRect();
    const triangleWidth = puzzleContainer.offsetWidth / 2;
    const triangleHeight = puzzleContainer.offsetHeight / 4;

    const pieceSpaceCenterX = pieceSpaceRect.left + pieceSpace.offsetWidth / 2;
    const pieceSpaceCenterY = pieceSpaceRect.top + pieceSpace.offsetHeight / 2;

    let adjustedTopPositionX = pieceSpaceCenterX;
    let adjustedTopPositionY = pieceSpaceCenterY - (triangleHeight / 2);

    switch (trueRotation) {
        case 90:
            adjustedTopPositionX = pieceSpaceCenterX + triangleHeight;
            adjustedTopPositionY = pieceSpaceCenterY;
            break;
        case 180:
            adjustedTopPositionX = pieceSpaceCenterX;
            adjustedTopPositionY = pieceSpaceCenterY + (triangleHeight / 2);
            break;
        case 270:
            adjustedTopPositionX = pieceSpaceCenterX - triangleHeight;
            adjustedTopPositionY = pieceSpaceCenterY;
            break;
    }

    // Show the spiral effect
    const spiralContainer = document.getElementById('spiral-effect-container');
    const spiralEffect = document.getElementById('spiral-effect');
    const swirlSound = document.getElementById('swirl-sound');

    if (spiralEffect && spiralContainer) {
        spiralEffect.style.display = 'block';
        spiralEffect.style.left = `${pieceSpaceCenterX - spiralEffect.clientWidth / 2}px`;
        spiralEffect.style.top = `${pieceSpaceCenterY - spiralEffect.clientHeight / 2}px`;

        // Play the swirl sound
        if (swirlSound) {
            swirlSound.currentTime = 0; // Reset sound to the start
            swirlSound.play();
        }

        // Hide the spiral after a short delay
        setTimeout(() => {
            spiralEffect.style.display = 'none';
        }, 1500); // Duration matches the time before triangle fully appears
    }
    logStoreContents('triangleSlices')
    createTriangle(nextTriangleId, pieceSpace, triangleWidth, triangleHeight);
    positionTriangle(nextTriangleId, adjustedTopPositionX, adjustedTopPositionY, trueRotation);

    const triangleElement = document.getElementById(nextTriangleId);
    if (triangleElement) {
        triangleElement.classList.add('triangle-appear');
        triangleElement.addEventListener('animationend', () => {
            triangleElement.classList.remove('triangle-appear');
        }, { once: true });
    }


    getImagesFromIndexedDB('triangleSlices', null, (triangleSlicesImages) => {
        const triangleImage = triangleSlicesImages?.find((img) => img.id === nextTriangleId);
        
        if (triangleImage) {
            const correctedRotation = trueRotation === 90 || trueRotation === 270 ? trueRotation + 180 : trueRotation;
            rotateBackgroundImage(triangleImage.data, correctedRotation, triangleElement);
        } else {
            console.warn(`No triangle image found for ${nextTriangleId} in IndexedDB.`);
        }
    });

    // Optional random rotation for visual variation
    const randomRotation = [0, 90, 180, 270][Math.floor(Math.random() * 4)];
    if (triangleElement) {
        triangleElement.style.transform = `rotate(${randomRotation}deg)`;
    }

    if (nextTriangleId === 'F2' || nextTriangleId === 'F5') {
        setTimeout(() => {
            demo(nextTriangleId);
        }, 1500);
    }

    makeTriangleInteractive(nextTriangleId);
    setupDragListeners();
}

export function logStoreContents(storeName) {
    openDatabase((db) => {
        const transaction = db.transaction([storeName], 'readonly');
        const store = transaction.objectStore(storeName);

        const getAllRequest = store.getAll();
        getAllRequest.onsuccess = (event) => {
            const results = event.target.result;
        };
        getAllRequest.onerror = (event) => {
            console.error(`Error retrieving all items from store '${storeName}':`, event.target.error);
        };
    });
}

    export function applyTriangleImages() {
        getImagesFromIndexedDB('triangleSlices', (savedTriangleImages) => {
            if (!savedTriangleImages || savedTriangleImages.length === 0) {
                console.log("No saved adjusted triangle images found in IndexedDB.");
                return;
            }
    
            // Define all dynamic triangles that need images applied
            const dynamicTriangles = ['F2', 'F5', 'E1', 'E3', 'E4', 'E6', 'D2', 'D5', 'C2', 'C5', 'B1', 'B3', 'B4', 'B6', 'A2', 'A5'];
    
            // Iterate over each triangle ID and apply its image from IndexedDB
            dynamicTriangles.forEach(triangleId => {
                // Attempt to find each triangle element in the DOM
                const triangleElement = document.getElementById(triangleId);
    
                if (!triangleElement) {
                    console.warn(`Triangle element with ID ${triangleId} not found in the DOM.`);
                    return; // Skip if the element isn't found
                }
    
                // Find the saved image data for this specific triangle ID in `adjustedTriangles`
                const triangleImage = savedTriangleImages.find(image => image.id === triangleId);
                const imgData = triangleImage?.data;
    
                if (!imgData) {
                    console.warn(`No image data found for triangle ID ${triangleId}.`);
                    return; // Skip if no image data is found
                }
    
                // Retrieve the correct rotation value from `triangleSnapVertices` for visual adjustment
                const snapData = triangleSnapVertices[triangleId];
                const rotationAngle = snapData.rotation;
    
                // Apply the background image with rotation correction
                rotateBackgroundImage(imgData, rotationAngle, triangleElement);
            });
        });
    }