
import { saveImageToIndexedDB, loadImageFromIndexedDB, loadFinalImageFromIndexedDB } from './DBUtils.js';
import { stopTimer, getFormattedElapsedTime } from './Timer.js';
import { sharePuzzleScore } from './ShareScore.js';
import { difficulty } from './menu.js';

export function handlePuzzleComplete() {
    // Stop the timer used in the game
    stopTimer();

    // Play the completion sound
    const completeSound = document.getElementById('complete-sound');
    if (completeSound && !completeSound.muted) {
        completeSound.play();
    }

    // Remove or hide the "I give up" button
    const giveUpButton = document.getElementById('complete-btn');
    if (giveUpButton) {
        giveUpButton.style.display = 'none'; // Hide the button
        // or
        giveUpButton.remove(); // Remove the button from the DOM
    }

    // Celebrate with confetti after a delay
    setTimeout(() => {
        confetti({
            particleCount: 100,
            spread: 100,
            origin: { y: 0.6 }
        });
    }, 1000);

    // Complete the puzzle manually after a delay
    setTimeout(() => {
        const puzzleContainer = document.getElementById('puzzle-container');
    if (puzzleContainer) {
        while (puzzleContainer.firstChild) {
            puzzleContainer.removeChild(puzzleContainer.firstChild);
        }
    }
        completePuzzleManually(); // Make sure this function doesn't recreate the "I give up" button
    }, 1500);
}

// Function to manually complete the puzzle using the pre-saved final image
export function completePuzzleManually() {
    console.log('Manually completing the puzzle using saved images.');
    revealFinalImage();
}

export async function revealFinalImage() {
    // Get the width and position of the puzzle container
    const puzzleContainer = document.getElementById('puzzle-container');
    const { width, top, left } = puzzleContainer.getBoundingClientRect();

    // Define a responsive vertical offset using viewport height (vh)
    const verticalOffsetVh = -7;
    const verticalOffsetPx = (verticalOffsetVh / 100) * window.innerHeight;

    // Retrieve the overlay image (unscrambledImage) from IndexedDB
    loadImageFromIndexedDB('unscrambledImage', (compiledPuzzleImage) => {
        if (!compiledPuzzleImage || !compiledPuzzleImage.originalImageSrc) {
            console.error('Overlay image (unscrambledImage) not found or invalid.');
            return;
        }
        console.log("Successfully retrieved overlay image:", compiledPuzzleImage);

        loadImageFromIndexedDB('originalImage', (polaroidImage) => {
            if (!polaroidImage || !polaroidImage.originalImageSrc) {
                console.error('Original polaroid image not found or invalid.');
                return;
            }
            console.log("Successfully retrieved original image:", polaroidImage);

            // Create and append the polaroid image
            const finalImage = new Image();
            finalImage.src = polaroidImage.originalImageSrc;
            finalImage.style.width = `${width}px`;
            finalImage.style.height = 'auto';
            finalImage.style.position = 'absolute';
            finalImage.style.top = `${top + verticalOffsetPx}px`;
            finalImage.style.left = `${left}px`;
            finalImage.style.zIndex = '1';
            document.body.appendChild(finalImage);

            // Create and append the puzzle overlay image
            const puzzleOverlay = new Image();
            puzzleOverlay.src = compiledPuzzleImage.originalImageSrc;
            puzzleOverlay.style.position = 'absolute';
            puzzleOverlay.style.width = `${width * 0.92}px`;
            puzzleOverlay.style.height = 'auto';
            puzzleOverlay.style.top = `${top + verticalOffsetPx + width * 0.04}px`;
            puzzleOverlay.style.left = `${left + width * 0.04}px`;
            puzzleOverlay.style.zIndex = '2';
            puzzleOverlay.style.pointerEvents = 'none';
            document.body.appendChild(puzzleOverlay);

            // Create and append the mask overlay
            const maskHeight = finalImage.getBoundingClientRect().height * 0.06; // 6% of finalImage height
            const maskOverlay = document.createElement('div');
            maskOverlay.style.position = 'absolute';
            maskOverlay.style.width = `${width}px`;
            maskOverlay.style.height = `${maskHeight}px`;
            maskOverlay.style.top = `${top + verticalOffsetPx + finalImage.getBoundingClientRect().height - maskHeight}px`;
            maskOverlay.style.left = `${left}px`;
            maskOverlay.style.backgroundColor = 'white';
            maskOverlay.style.zIndex = '10000';
            document.body.appendChild(maskOverlay);
            

            // Ensure all images are loaded before proceeding
            let imagesLoaded = 0;
            const checkImagesLoaded = () => {
                imagesLoaded++;
                if (imagesLoaded === 2) {
                    // Both images are loaded, proceed with drawing and saving
                    saveFinalImageToIndexedDB(puzzleContainer, finalImage, puzzleOverlay, maskOverlay);
                    displayCompletionButtons();
                }
            };

            finalImage.onload = checkImagesLoaded;
            puzzleOverlay.onload = checkImagesLoaded;

            // If images are already cached and loaded, call the function immediately
            if (finalImage.complete) checkImagesLoaded();
            if (puzzleOverlay.complete) checkImagesLoaded();
        });
    });
}

// Updated saveFinalImageToIndexedDB Function
function saveFinalImageToIndexedDB(puzzleContainer, polaroidImage, puzzleOverlay, maskOverlay) {
    if (!polaroidImage || !puzzleOverlay || !maskOverlay) {
        console.error('Polaroid image, puzzle overlay, or mask overlay not found for drawing.');
        return;
    }

    const polaroidWidth = polaroidImage.naturalWidth;
    const polaroidHeight = polaroidImage.naturalHeight;
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    canvas.width = polaroidWidth;
    canvas.height = polaroidHeight;

    ctx.drawImage(polaroidImage, 0, 0, canvas.width, canvas.height);

    const overlayWidth = polaroidWidth * 0.92;
    const overlayHeight = overlayWidth * (puzzleOverlay.naturalHeight / puzzleOverlay.naturalWidth);
    const overlayX = (polaroidWidth - overlayWidth) / 2;
    const overlayY = (polaroidHeight - overlayHeight) / 2 - (polaroidHeight * 0.08);

    ctx.drawImage(puzzleOverlay, overlayX, overlayY, overlayWidth, overlayHeight);

    // Draw the white mask overlay
    const maskHeight = polaroidHeight * 0.06; // 6% of the polaroid height
    const maskY = polaroidHeight - maskHeight; // Position at the bottom
    ctx.fillStyle = 'white';
    ctx.fillRect(0, maskY, polaroidWidth, maskHeight);

    const combinedImageDataURL = canvas.toDataURL('image/png');
    saveImageToIndexedDB('finalCombinedImage', { id: 'final', data: combinedImageDataURL })
        .then(() => {
            console.log('Final combined puzzle image saved to IndexedDB.');
            loadFinalImageFromIndexedDB('final', (imageData) => {
                if (imageData && imageData.data === combinedImageDataURL) {
                    console.log('Verification successful: Image data exists in IndexedDB and matches saved data.');
                } else if (imageData) {
                    console.warn('Image data found but does not match expected data.');
                } else {
                    console.error('Verification failed: Image data not found in IndexedDB after save.');
                }
            });
        });
}



// Modified saveImage function
export function saveImage(dataUrl) {
    // Generate a unique file name based on the current date and time
    const uniqueFileName = generateUniqueFileName();

    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = `${uniqueFileName}.png`;

    if (typeof link.download !== 'undefined') {
        link.click();
        // Show the notification after saving the image
        showSaveNotification();
    } else {
        const newWindow = window.open(dataUrl, '_blank');
        if (newWindow) {
            newWindow.focus();
            // Show the notification if the image is opened in a new tab
            showSaveNotification();
        } else {
            alert('Please allow popups to save the image.');
        }
    }
}

function generateUniqueFileName() {
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `Puzzle${day}${month}${year}${hours}${minutes}${seconds}`;
}

// Function to display the notification
function showSaveNotification() {
    // Create a container for the notification
    const notificationContainer = document.createElement('div');
    notificationContainer.classList.add('save-notification');

    // Create the message text
    const messageText = document.createElement('p');
    messageText.innerText = 'Your Pique will been saved to your device\'s default folder.';
    notificationContainer.appendChild(messageText);

    // Create the "OK" button
    const okButton = document.createElement('button');
    okButton.innerText = 'OK';
    okButton.classList.add('ok-button');
    okButton.onclick = () => {
        // Remove the notification from the DOM when "OK" is clicked
        notificationContainer.remove();
    };
    notificationContainer.appendChild(okButton);

    // Append the notification to the body
    document.body.appendChild(notificationContainer);
}


// Function to be called at the end of puzzle completion to trigger the save and display
function finalizePuzzleDisplay() {
    const puzzleContainer = document.getElementById('puzzle-container');
    if (puzzleContainer) {
        saveFinalImageToIndexedDB(puzzleContainer);
    }
}



export function displayCompletionButtons() {
    const pieceSpace = document.getElementById('piece-space'); 
    pieceSpace.innerHTML = '';

    // Create the button container and apply the CSS class
    const buttonContainer = document.createElement('div');
    buttonContainer.classList.add('button-container');

// SAVE BUTTON
const saveButton = document.createElement('button');
    saveButton.innerText = 'Save Pique';
    saveButton.classList.add('completion-button');
    saveButton.onclick = () => {
        console.log('Save button clicked');

        // Load and save the final combined image from IndexedDB
        loadFinalImageFromIndexedDB('final', (imageData) => {
            if (imageData && imageData.data) {
                console.log('Final image data loaded successfully:', imageData);

                // Call saveImage with the actual data URL for saving
                saveImage(imageData.data); // Pass the data URL to saveImage for download
            } else {
                console.error('Failed to load final combined image from IndexedDB.');
            }
        });
    };



    // SEND BUTTON

const sendButton = document.createElement('button');
    sendButton.innerText = 'Send a Pique';
    sendButton.classList.add('completion-button');
    sendButton.onclick = () => {
    const iosScheme = 'puzzleapp://';
    const appStoreUrl = 'https://apps.apple.com/us/app/your-puzzle-app/idXXXXXXXXX';

    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = iosScheme;
    document.body.appendChild(iframe);

    setTimeout(() => {
        // Open the App Store URL in a new tab or window
        window.open(appStoreUrl, '_blank');
    }, 2500);

    setTimeout(() => {
        document.body.removeChild(iframe);
    }, 3000);
};

// REPLAY BUTTON

const replayButton = document.createElement('button');
    replayButton.innerText = 'Restart';
    replayButton.classList.add('completion-button');
    replayButton.onclick = () => {
        location.reload();
    };

 // SHARE SCORE BUTTON
 const shareScoreButton = document.createElement('button');
 shareScoreButton.innerText = 'Share Score';
 shareScoreButton.classList.add('completion-button');
 shareScoreButton.onclick = () => {
     // Get the difficulty and time from your game logic
     const timeTaken = getFormattedElapsedTime(); // Use the formatted elapsed time

     // Call the sharePuzzleScore function
     sharePuzzleScore(difficulty, timeTaken);
 };

 // Append all buttons to the container
 buttonContainer.appendChild(saveButton);
 buttonContainer.appendChild(sendButton);
 buttonContainer.appendChild(replayButton);
 buttonContainer.appendChild(shareScoreButton);

 // Append the container to the pieceSpace
 pieceSpace.appendChild(buttonContainer);
}