import jsQR from 'jsqr';

export function checkForQRCode(imageSrc, callback) {
    const image = new Image();
    image.src = imageSrc;

    image.onload = () => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');

        // Set canvas dimensions to match the image
        canvas.width = image.width;
        canvas.height = image.height;
        context.drawImage(image, 0, 0, image.width, image.height);

        // Define the area to check for the QR code
        const qrCodeRegionWidth = 660;
        const qrCodeRegionHeight = 222;
        const qrCodeRegionX = image.width - qrCodeRegionWidth - 20;
        const qrCodeRegionY = image.height - qrCodeRegionHeight - 20;

        // Get image data from the defined area
        const imageData = context.getImageData(
            qrCodeRegionX,
            qrCodeRegionY,
            qrCodeRegionWidth,
            qrCodeRegionHeight
        );

        // Use jsQR to detect QR code in the specified area
        const qrCode = jsQR(imageData.data, qrCodeRegionWidth, qrCodeRegionHeight);

        if (qrCode) {
            if (qrCode.data === "https://www.piquepic.com") {
                callback(true); // Valid PiquePic QR code
            } else {
                console.warn("QR Code data mismatch. Detected:", qrCode.data);
                callback(false); // QR code does not match
            }
        } else {
            console.log("No QR Code detected in the specified area.");
            callback(false); // No QR code found
        }
    };

    image.onerror = () => {
        console.error("Error loading image for QR code check.");
        callback(false);
    };
}