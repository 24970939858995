// Timer.js
let startTime;
let timerInterval;
let elapsedTime = 0;

export function startTimer() {
    startTime = Date.now();
    const timerElement = document.getElementById('timer');

    if (timerElement) {
        timerElement.classList.remove('hidden');
    }

    timerInterval = setInterval(() => {
        elapsedTime = Date.now() - startTime;
        const minutes = Math.floor(elapsedTime / 60000);
        const seconds = Math.floor((elapsedTime % 60000) / 1000);
        const tenths = Math.floor((elapsedTime % 1000) / 100); // Tenths of a second

        if (timerElement) {
            timerElement.textContent = `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}.${tenths}`;
        }
    }, 100);
}

export function stopTimer(reset = false) {
    clearInterval(timerInterval);

    if (reset) {
        elapsedTime = 0;
        const timerElement = document.getElementById('timer');
        if (timerElement) {
            timerElement.textContent = "00:00.0";
        }
    }
}

// Function to get the formatted elapsed time
export function getFormattedElapsedTime() {
    const minutes = Math.floor(elapsedTime / 60000);
    const seconds = Math.floor((elapsedTime % 60000) / 1000);
    const tenths = Math.floor((elapsedTime % 1000) / 100);
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}.${tenths}`;
}

// Add an event listener to keep the timer visible when the screen is tapped
document.addEventListener('pointerdown', () => {
    const timerElement = document.getElementById('timer');
    if (timerElement) {
        timerElement.style.opacity = '1';
    }
});