import { initializeGame } from './TrianglePuzzle.js';
import { initializeCircleGame } from './CirclePuzzle.js';
import { initializeSquarePuzzle } from './SquarePuzzle.js';

let difficulty;

export function initializeMenu(unscrambledImageURL) {
    const blingSound = document.getElementById('bling-sound');

    // Get containers
    const puzzleContainer = document.getElementById('puzzle-container');
    const pieceSpace = document.getElementById('piece-space');

    // Create the carousel container for images
    const carouselContainer = document.createElement('div');
    carouselContainer.style.width = '100%';
    carouselContainer.style.height = '100%';
    carouselContainer.style.display = 'flex';
    carouselContainer.style.alignItems = 'center';
    carouselContainer.style.justifyContent = 'center';
    carouselContainer.style.position = 'relative'; // Make the carousel relative
    carouselContainer.style.zIndex = '10'; // Ensure the carousel is on top
    puzzleContainer.appendChild(carouselContainer); // Append to puzzle container

    // Create puzzle images
    const trianglePuzzleImage = document.createElement('img');
    trianglePuzzleImage.src = './images/triangle-puzzle.jpg';
    trianglePuzzleImage.alt = 'Triangle Puzzle';
    trianglePuzzleImage.style.width = '100%';
    trianglePuzzleImage.style.height = '100%';
    trianglePuzzleImage.style.cursor = 'pointer';
    trianglePuzzleImage.style.zIndex = '15';
    trianglePuzzleImage.style.position = 'relative';
    trianglePuzzleImage.style.borderRadius = '20px'; // Slight rounding for a smoother fade
    trianglePuzzleImage.classList.add('fade-edges');
    
    const circlePuzzleImage = document.createElement('img');
    circlePuzzleImage.src = './images/circle-puzzle.jpg';
    circlePuzzleImage.alt = 'Circle Puzzle';
    circlePuzzleImage.style.width = '100%';
    circlePuzzleImage.style.height = '100%';
    circlePuzzleImage.style.cursor = 'pointer';
    circlePuzzleImage.style.zIndex = '15';
    circlePuzzleImage.style.position = 'relative';
    circlePuzzleImage.style.borderRadius = '20px';
    circlePuzzleImage.classList.add('fade-edges');
    
    const squarePuzzleImage = document.createElement('img');
    squarePuzzleImage.src = './images/square-puzzle.jpg';
    squarePuzzleImage.alt = 'Slider Puzzle';
    squarePuzzleImage.style.width = '100%';
    squarePuzzleImage.style.height = '100%';
    squarePuzzleImage.style.cursor = 'pointer';
    squarePuzzleImage.style.zIndex = '15';
    squarePuzzleImage.style.position = 'relative';
    squarePuzzleImage.style.borderRadius = '20px';
    squarePuzzleImage.classList.add('fade-edges');

    // Create the arrow buttons
    const leftArrow = document.createElement('button');
    leftArrow.textContent = '←';
    leftArrow.classList.add('arrow-button'); // Add the class for styling

    const rightArrow = document.createElement('button');
    rightArrow.textContent = '→';
    rightArrow.classList.add('arrow-button'); // Add the class for styling

    // Create the "CHOOSE A GAME" text
    const chooseText = document.createElement('div');
    chooseText.textContent = 'CHOOSE A GAME';
    chooseText.classList.add('choose-game-text'); // Add the class for styling

    // Append elements to the piece space
    pieceSpace.appendChild(leftArrow);
    pieceSpace.appendChild(chooseText);
    pieceSpace.appendChild(rightArrow);

    // Append images to the carousel container
    carouselContainer.appendChild(circlePuzzleImage); // Initially display the circle image

    let puzzleOptions = ['circle', 'triangle', 'slider']; // Updated order
    let currentIndex = 0;

    function playBlingSound() {
        if (blingSound) {
            blingSound.play().catch((error) => {
                console.error('Error playing bling sound:', error);
            });
        }
    }

    // Update displayed puzzle based on current index
    function updateDisplayedPuzzle() {
        const currentPuzzle = puzzleOptions[currentIndex];
        carouselContainer.replaceChild(
            currentPuzzle === 'circle'
                ? circlePuzzleImage
                : currentPuzzle === 'triangle'
                ? trianglePuzzleImage
                : squarePuzzleImage, // Display the correct image
            carouselContainer.children[0] // Replace the central image
        );
    }

    // Left arrow event - moves backward, wraps to the end if at the start
    leftArrow.addEventListener('click', () => {
        playBlingSound(); // Play sound
        currentIndex = (currentIndex - 1 + puzzleOptions.length) % puzzleOptions.length;
        updateDisplayedPuzzle();
    });

    // Right arrow event - moves forward, wraps to the beginning if at the end
    rightArrow.addEventListener('click', () => {
        playBlingSound(); // Play sound
        currentIndex = (currentIndex + 1) % puzzleOptions.length;
        updateDisplayedPuzzle();
    });

 // Event listeners for starting the selected game
 trianglePuzzleImage.addEventListener('click', () => {
    playBlingSound();
    difficulty = "Medium"; // Set difficulty directly
    puzzleContainer.removeChild(carouselContainer);
    pieceSpace.removeChild(leftArrow);
    pieceSpace.removeChild(chooseText);
    pieceSpace.removeChild(rightArrow);
    initializeGame(unscrambledImageURL);
});

circlePuzzleImage.addEventListener('click', () => {
    playBlingSound();
    difficulty = "Easy"; // Set difficulty directly
    puzzleContainer.removeChild(carouselContainer);
    pieceSpace.removeChild(leftArrow);
    pieceSpace.removeChild(chooseText);
    pieceSpace.removeChild(rightArrow);
    initializeCircleGame(unscrambledImageURL);
});

squarePuzzleImage.addEventListener('click', () => {
    playBlingSound();
    difficulty = "Hard"; // Set difficulty directly
    puzzleContainer.removeChild(carouselContainer);
    pieceSpace.removeChild(leftArrow);
    pieceSpace.removeChild(chooseText);
    pieceSpace.removeChild(rightArrow);
    initializeSquarePuzzle();
});
}

export { difficulty };