import { unscrambleImage } from "./unscrambler.js";
import { saveImageToIndexedDB, clearIndexedDB } from "./DBUtils.js";
import { setupDragListeners } from "./DragUtility.js";
import { setupDropListeners } from "./DropUtility.js";
import { checkForQRCode } from "./QRCodeChecker.js";

export function setupLandingPage() {
    console.log('Landing Page Initialized version 3.11');

    const blingSound = document.getElementById('bling-sound');

    // Setup event listener for the "Unscramble a Pique" button
    const unscrambleButton = document.getElementById('unscramble-button');
    if (unscrambleButton) {
        console.log('Unscramble button found and event listener attached');
        unscrambleButton.addEventListener('click', () => {
            console.log('Unscramble button clicked');

            // Play the bling sound
            if (blingSound) {
                blingSound.play().catch((error) => {
                    console.error('Error playing bling sound:', error);
                });
            }

            const fileSelector = document.getElementById('file-selector');
            if (fileSelector) {
                fileSelector.click();  // Trigger the file input dialog
            } else {
                console.error('File selector not found');
            }
        });
    } else {
        console.error('Unscramble button not found');
    }

    // Set even dimensions for the puzzle container
    setEvenDimensions("puzzle-container");

    // Clear session storage if available
    if (typeof sessionStorage !== 'undefined') {
        sessionStorage.clear();
    } else {
        console.warn('Session storage is not supported in this browser or mode.');
    }

    // Clear the 'images' store in IndexedDB
    clearIndexedDB('images');

    // Setup drag and drop listeners
    setupDragListeners();
    setupDropListeners();

    // Handle image input from the file selector
    const fileSelector = document.getElementById('file-selector');
    if (fileSelector) {
        fileSelector.addEventListener('change', function() {
            const file = this.files[0];
            if (file) {
                const reader = new FileReader();
                reader.onload = function(event) {
                    const imageSrc = event.target.result;

                    // Check for QR code in the selected image
                    checkForQRCode(imageSrc, (isValidQRCode) => {
                        if (!isValidQRCode) {
                            // Create a full-screen overlay
                            const overlay = document.createElement('div');
                            overlay.style.position = 'fixed';
                            overlay.style.top = '0';
                            overlay.style.left = '0';
                            overlay.style.width = '100%';
                            overlay.style.height = '100%';
                            overlay.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
                            overlay.style.zIndex = '10000';
                            overlay.style.display = 'flex';
                            overlay.style.flexDirection = 'column';
                            overlay.style.justifyContent = 'center';
                            overlay.style.alignItems = 'center';
                            document.body.appendChild(overlay);

                            // Create the warning message
                            const warningMessage = document.createElement('div');
                            warningMessage.innerText = "I'm sorry, this doesn't seem to be a Pique Pic. Please select another image.";
                            warningMessage.style.color = 'white';
                            warningMessage.style.textAlign = 'center';
                            warningMessage.style.marginBottom = '20px';
                            overlay.appendChild(warningMessage);

                            // Create the "OK" button
                            const okButton = document.createElement('button');
                            okButton.innerText = "OK";
                            okButton.style.padding = '10px 20px';
                            okButton.style.cursor = 'pointer';
                            okButton.onclick = () => {
                                // Play the bling sound
                                if (blingSound) {
                                    blingSound.play().catch((error) => {
                                        console.error('Error playing bling sound:', error);
                                    });
                                }
                                location.reload(); // Reload the main page
                            };
                            overlay.appendChild(okButton);

                            return; // Exit if the QR code is invalid
                        }

                        // If the QR code is valid, proceed with loading the image
                        const img = new Image();
                        img.onload = function() {
                            // Save the image source to IndexedDB
                            saveImageToIndexedDB('images', { id: 'originalImage', originalImageSrc: imageSrc })
                                .then(() => {
                                    console.log('Image saved successfully with key: originalImage');
                                    // Call unscrambleImage with gridSize 10
                                    unscrambleImage(10);

                                    // Hide the landing content once everything is ready
                                    completeLandingSetup();
                                })
                                .catch((error) => {
                                    console.error('Error saving image:', error);
                                });
                        };
                        img.src = imageSrc;
                    });
                };
                reader.readAsDataURL(file);
            }
        });
    }
}

export function completeLandingSetup() {
    const landingContent = document.getElementById('landing-content');
    if (landingContent) {
        landingContent.style.display = 'none'; // Hide the landing content
    } else {
        console.error('Landing content not found');
    }
}

// Function to round dimensions of an element to the nearest even number
export function setEvenDimensions(elementId) {
    const container = document.getElementById(elementId);
    if (container) {
        const computedWidth = container.offsetWidth;
        const computedHeight = container.offsetHeight;

        // Round width and height to the nearest even number
        const evenWidth = Math.round(computedWidth / 2) * 2;
        const evenHeight = Math.round(computedHeight / 2) * 2;

        // Apply the even dimensions
        container.style.width = `${evenWidth}px`;
        container.style.height = `${evenHeight}px`;
    }
}