import { getImagesFromIndexedDB } from './DBUtils.js';
import { handlePuzzleComplete } from './PuzzleCompletion.js';
import { displayReadySetGo } from "./Demo.js";

export async function initializeSquarePuzzle() {
    console.log('RUNNING initializeSquarePuzzle version 8');

    getImagesFromIndexedDB('images', null, (images) => {
        const imageRecord = images.find((img) => img.id === 'unscrambledImage');

        if (!imageRecord || !imageRecord.originalImageSrc) {
            console.error('Failed to retrieve the unscrambled image from IndexedDB');
            return;
        }

        const img = new Image();
        img.src = imageRecord.originalImageSrc;

        img.onload = function () {
            const puzzleContainer = document.getElementById('puzzle-container');
            if (!puzzleContainer) {
                console.error('Puzzle container not found');
                return;
            }

            // Calculate canvasSize to fit within the puzzleContainer's boundaries
            const canvasSize = Math.min(puzzleContainer.offsetWidth, puzzleContainer.offsetHeight);
            puzzleContainer.style.width = `${canvasSize}px`;
            puzzleContainer.style.height = `${canvasSize}px`;

            const tileSizeX = canvasSize / 3; // Width of each tile
            const tileSizeY = canvasSize / 3; // Height of each tile
            const tiles = [];

            // Create tiles and store the correct (original) positions
            const originalPositions = []; // This array should not change
            for (let y = 0; y < 3; y++) {
                for (let x = 0; x < 3; x++) {
                    if (x === 2 && y === 2) continue; // Leave the bottom-right space empty

                    const tile = document.createElement('div');
                    tile.classList.add('tile');
                    tile.style.width = `${tileSizeX}px`;
                    tile.style.height = `${tileSizeY}px`;
                    tile.style.backgroundImage = `url(${img.src})`;
                    tile.style.backgroundSize = `${canvasSize}px ${canvasSize}px`;
                    tile.style.backgroundPosition = `-${x * tileSizeX}px -${y * tileSizeY}px`;
                    tile.style.position = 'absolute';
                    tile.style.border = '2px solid purple'; // Add the purple border
                    tile.dataset.originalPosition = `${x}-${y}`; // Correct original position
                    originalPositions.push(`${x}-${y}`); // Store the original positions
                    tile.dataset.position = `${x}-${y}`; // Initialize current position
                    tiles.push(tile);
                }
            }

            // Now, shuffle the tiles to create a scrambled, but solvable, state
            do {
                shuffleTiles(tiles); // Shuffle without affecting originalPositions
            } while (!isSolvable(tiles));

            // Append tiles to the container in their scrambled positions
            let emptyX = 2;
            let emptyY = 2;
            tiles.forEach((tile, index) => {
                const x = index % 3;
                const y = Math.floor(index / 3);
                tile.style.top = `${y * tileSizeY}px`;
                tile.style.left = `${x * tileSizeX}px`;
                tile.dataset.position = `${x}-${y}`; // Set the scrambled position
                puzzleContainer.appendChild(tile);
            });

            setupSlideLogic(tiles, tileSizeX, tileSizeY, emptyX, emptyY, originalPositions);
            displayReadySetGo()
        };

        img.onerror = () => console.error('Failed to load the unscrambled image');
    });
}

// Fisher-Yates Shuffle Algorithm
function shuffleTiles(tiles) {
    for (let i = tiles.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [tiles[i], tiles[j]] = [tiles[j], tiles[i]]; // Swap elements
    }
}

// Check if the puzzle is solvable
function isSolvable(tiles) {
    const tilePositions = tiles.map(tile => {
        const [x, y] = tile.dataset.originalPosition.split('-').map(Number);
        return y * 3 + x; // Flattened position
    });

    let inversions = 0;
    for (let i = 0; i < tilePositions.length - 1; i++) {
        for (let j = i + 1; j < tilePositions.length; j++) {
            if (tilePositions[i] > tilePositions[j]) inversions++;
        }
    }

    return inversions % 2 === 0; // Solvable if inversions are even
}

function setupSlideLogic(tiles, tileSizeX, tileSizeY, emptyX, emptyY, originalPositions) {
    const blingSound = document.getElementById('snap-sound');
    tiles.forEach((tile) => {
        const moveTile = () => {
            const [tileX, tileY] = tile.dataset.position.split('-').map(Number);

            if (
                (Math.abs(tileX - emptyX) === 1 && tileY === emptyY) ||
                (Math.abs(tileY - emptyY) === 1 && tileX === emptyX)
            ) {
                // Move the tile
                tile.style.top = `${emptyY * tileSizeY}px`;
                tile.style.left = `${emptyX * tileSizeX}px`;
                tile.dataset.position = `${emptyX}-${emptyY}`;

                // Play the sound effect
                if (blingSound) {
                    blingSound.play();
                }

                emptyX = tileX;
                emptyY = tileY;

                if (checkPuzzleCompletion(tiles, originalPositions)) {
                    console.log('Puzzle complete!');

                    handlePuzzleComplete();
                }
            }
        };

        tile.addEventListener('click', moveTile);
        tile.addEventListener('touchend', moveTile);
    });
}

function checkPuzzleCompletion(tiles, originalPositions) {
    return tiles.every((tile) => {
        const [tileX, tileY] = tile.dataset.position.split('-').map(Number);
        const originalPosition = tile.dataset.originalPosition;
        return `${tileX}-${tileY}` === originalPosition;
    });
}