// Open the database or return the existing instance
let dbInstance = null;

export function openDatabase(callback) {
    if (dbInstance) {
        callback(dbInstance); // Reuse the existing instance
        return;
    }

    const request = indexedDB.open('PuzzleAppDB', 6); // Incremented to version 4

    request.onupgradeneeded = (event) => {
        const db = event.target.result;

        if (!db.objectStoreNames.contains('images')) {
            db.createObjectStore('images', { keyPath: 'id', autoIncrement: false });
            console.log('Created store: images');
        }

        if (!db.objectStoreNames.contains('triangleImages')) {
            db.createObjectStore('triangleImages', { keyPath: 'id', autoIncrement: false });
            console.log('Created store: triangleImages');
        }

        if (!db.objectStoreNames.contains('triangleSlices')) {
            db.createObjectStore('triangleSlices', { keyPath: 'id' });
            console.log('Created store: triangleSlices');
        }

        if (!db.objectStoreNames.contains('finalImage')) {
            db.createObjectStore('finalImage', { keyPath: 'id' });
            console.log('Created store: finalImage');
        }

        // Ensure finalCombinedImage store is created
        if (!db.objectStoreNames.contains('finalCombinedImage')) {
            db.createObjectStore('finalCombinedImage', { keyPath: 'id' });
            console.log('Created store: finalCombinedImage');
        }
    };

    request.onsuccess = (event) => {
        dbInstance = event.target.result;

        if (
            dbInstance.objectStoreNames.contains('images') &&
            dbInstance.objectStoreNames.contains('triangleImages') &&
            dbInstance.objectStoreNames.contains('triangleSlices') &&
            dbInstance.objectStoreNames.contains('finalImage') &&
            dbInstance.objectStoreNames.contains('finalCombinedImage') // Confirm finalCombinedImage is created
        ) {
            console.log("Database initialized with all required stores confirmed.");
            callback(dbInstance);
        } else {
            console.error("Initialization issue: one or more stores are missing.");
        }
    };

    request.onerror = (event) => {
        console.error("Error opening IndexedDB:", event.target.error);
    };
}

export function saveImageToIndexedDB(storeName, imageData, key) {
    return new Promise((resolve, reject) => {
        openDatabase((db) => {
            const transaction = db.transaction([storeName], 'readwrite');
            const store = transaction.objectStore(storeName);

            const saveRequest = key ? store.put({ ...imageData, id: key }) : store.put(imageData);

            saveRequest.onsuccess = () => {
                console.log(`Image data saved to IndexedDB in store ${storeName} with key: ${key || imageData.id}`);
                resolve();
            };

            saveRequest.onerror = (event) => {
                console.error('Error saving image to IndexedDB:', event.target.error);
                reject(event.target.error);
            };
        });
    });
}

// DBUtils.js

export function getImagesFromIndexedDB(storeName, key = null, callback) {
    openDatabase((db) => {
        const transaction = db.transaction([storeName], 'readonly');
        const store = transaction.objectStore(storeName);

        console.log(`Retrieving from store: ${storeName}, with key: ${key}`);
        console.log(`Key type is: ${typeof key}`); // Check key type

        if (key) {
            // Retrieve specific item
            const getRequest = store.get(key);

            getRequest.onsuccess = (event) => {
                const result = event.target.result;
                if (result) {
                    console.log(`Successfully retrieved item with key '${key}':`, result);
                    callback(result);
                } else {
                    console.error(`Item with key '${key}' not found in store '${storeName}'`);
                    callback(null);
                }
            };

            getRequest.onerror = (event) => {
                console.error('Error retrieving item:', event.target.error);
                callback(null);
            };
        } else {
            // Retrieve all items if no key is provided
            const getAllRequest = store.getAll();

            getAllRequest.onsuccess = (event) => {
                console.log(`Successfully retrieved all items from store '${storeName}'`);
                callback(event.target.result);
            };

            getAllRequest.onerror = (event) => {
                console.error('Error retrieving all items:', event.target.error);
                callback(null);
            };
        }
    });
}


// Load a single image from the specified store using a specific key
export function loadImageFromIndexedDB(key, callback = () => {}) {
    if (typeof callback !== 'function') {
        console.error(`Invalid callback provided to loadImageFromIndexedDB for key: ${key}`);
        return; // Prevents executing an invalid callback
    }

    openDatabase((db) => {
        const transaction = db.transaction(['images'], 'readonly');
        const store = transaction.objectStore('images');

        const getRequest = store.get(key);
        getRequest.onsuccess = (event) => {
            const result = event.target.result;
            if (result) {
                console.log(`Image with key '${key}' loaded from IndexedDB.`);
                callback(result);
            } else {
                console.error(`Image with key '${key}' not found in IndexedDB.`);
                callback(null);
            }
        };
        getRequest.onerror = (e) => {
            console.error('Error loading image from IndexedDB:', e.target.error);
            callback(null);
        };
    });
}

export function loadFinalImageFromIndexedDB(key, callback = () => {}) {
    if (typeof callback !== 'function') {
        console.error(`Invalid callback provided to loadImageFromIndexedDB for key: ${key}`);
        return; // Prevents executing an invalid callback
    }

    openDatabase((db) => {
        const transaction = db.transaction(['finalCombinedImage'], 'readonly');
        const store = transaction.objectStore('finalCombinedImage');

        const getRequest = store.get(key);
        getRequest.onsuccess = (event) => {
            const result = event.target.result;
            if (result) {
                console.log(`Image with key '${key}' loaded from IndexedDB.`);
                callback(result);
            } else {
                console.error(`Image with key '${key}' not found in IndexedDB.`);
                callback(null);
            }
        };
        getRequest.onerror = (e) => {
            console.error('Error loading image from IndexedDB:', e.target.error);
            callback(null);
        };
    });
}

// Clear data from a specific object store
export function clearIndexedDB(storeName) {
    openDatabase((db) => {
        const transaction = db.transaction([storeName], 'readwrite');
        const store = transaction.objectStore(storeName);

        store.clear().onsuccess = () => {
            console.log(`${storeName} store cleared.`);
        };

        transaction.onerror = (event) => {
            console.error(`Error clearing ${storeName} store:`, event.target.error);
        };
    });
}