import { calculatePrimaryVertexPosition } from "./UI.js";
import { vertexPositions, createNextTriangleInPieceSpace } from "./TrianglePuzzle.js";
import { startTimer } from "./Timer.js";

let demoRunning = false;
let demoCompleted = { F2: false, F5: false }; // Track completion of each triangle separately
let doubleTapMessageShown = false; // Flag to track if the double-tap message has been shown

export function demo(triangleId) {
    console.log(`Demo function is running for triangle: ${triangleId}`);

    demoRunning = true;

    const triangle = document.getElementById(triangleId);
    const puzzleContainer = document.getElementById('puzzle-container');
    const snapSound = document.getElementById('snap-sound');
    const doubleTapMessage = document.getElementById('double-tap-message');
    const interactionLock = document.getElementById('interaction-lock'); // Get the overlay element

    if (!triangle || !puzzleContainer) {
        console.error('Triangle or puzzle container not found for demo.');
        return;
    }

    // Show the interaction lock when the demo starts
    if (interactionLock) {
        interactionLock.style.display = 'block';
    }

    // Show the double-tap message if it hasn't been shown before
    if (doubleTapMessage && !doubleTapMessageShown) {
        doubleTapMessage.style.opacity = '1';
        doubleTapMessage.style.visibility = 'visible';

        setTimeout(() => {
            doubleTapMessage.style.opacity = '0';
            doubleTapMessage.style.visibility = 'hidden';
        }, 2000);

        doubleTapMessageShown = true; // Set the flag to true so it won't show again
    }

    // Create or get the finger icon element
    let fingerIcon = document.getElementById('finger-icon');
    if (!fingerIcon) {
        fingerIcon = document.createElement('img');
        fingerIcon.id = 'finger-icon';
        fingerIcon.src = './icons/finger-icon.png';
        fingerIcon.style.position = 'absolute';
        fingerIcon.style.width = '25%';
        fingerIcon.style.height = '25%';
        fingerIcon.style.zIndex = '100';
        puzzleContainer.appendChild(fingerIcon);
    }

    const currentRotation = parseInt(triangle.style.transform.replace(/[^0-9]/g, '')) || 0;

    // Calculate the primary vertex based on the current rotation
    const triangleWidth = triangle.offsetWidth;
    const triangleHeight = triangle.offsetHeight;
    const { x: primaryVertexX, y: primaryVertexY } = calculatePrimaryVertexPosition(triangle, triangleWidth, triangleHeight, currentRotation);

    // Position the finger icon at the primary vertex
    const fingerOffsetX = fingerIcon.clientWidth / 2;
    const fingerOffsetY = fingerIcon.clientHeight / 2;
    fingerIcon.style.left = `${primaryVertexX - fingerOffsetX}px`;
    fingerIcon.style.top = `${primaryVertexY - fingerOffsetY}px`;
    fingerIcon.style.display = 'block'; // Ensure it's visible

    // Define the target snap vertex based on the triangle ID
    let targetX, targetY, finalRotation;

    if (triangleId === 'F2') {
        targetX = vertexPositions['V4'].x;
        targetY = vertexPositions['V4'].y;
        finalRotation = 0;  // F2 is at 0 degrees
    } else if (triangleId === 'F5') {
        targetX = vertexPositions['V5'].x;
        targetY = vertexPositions['V5'].y;
        finalRotation = 0;  // F5 is at 0 degrees
    } else {
        console.error('Invalid triangle ID for demo:', triangleId);
        return;
    }

    const deltaX = targetX - primaryVertexX;
    const deltaY = targetY - primaryVertexY;

    let newLeft = parseFloat(triangle.style.left || 0) + deltaX;
    let newTop = parseFloat(triangle.style.top || 0) + deltaY;

    switch (currentRotation) {
        case 90:
            newLeft -= triangleHeight - (0.75 * triangleWidth);
            newTop += triangleWidth / 4;
            break;
        case 180:
            newLeft -= triangleWidth / 2 - (0.5 * triangleWidth);
            newTop += triangleHeight / 2 + (0.5 * triangleHeight);
            break;
        case 270:
            newLeft -= triangleHeight / 2;
            newTop += triangleWidth / 4;
            break;
        default:
            break;
    }

    const transitionDuration = '2s';
    triangle.style.transition = `left ${transitionDuration} ease, top ${transitionDuration} ease, transform ${transitionDuration} ease`;
    triangle.style.left = `${newLeft}px`;
    triangle.style.top = `${newTop}px`;
    triangle.style.transform = `rotate(${finalRotation}deg)`;

    // Update the finger icon position dynamically as the triangle moves
    triangle.addEventListener('transitionrun', () => {
        const interval = setInterval(() => {
            const { x: updatedX, y: updatedY } = calculatePrimaryVertexPosition(triangle, triangleWidth, triangleHeight, finalRotation);
            fingerIcon.style.left = `${updatedX - fingerOffsetX}px`;
            fingerIcon.style.top = `${updatedY - fingerOffsetY}px`;
        }, 16); // Update every ~16ms (60 frames per second)

        triangle.addEventListener('transitionend', () => {
            clearInterval(interval);
            triangle.draggable = false;
            triangle.style.pointerEvents = 'none';
            snapSound.play();

            triangle.classList.add('glow');
            setTimeout(() => triangle.classList.remove('glow'), 1000);

            // Hide the finger icon after the demo completes
            fingerIcon.style.display = 'none';

            demoCompleted[triangleId] = true;
            createNextTriangleInPieceSpace();

            // Check if both triangles (F2 and F5) are complete before hiding the overlay
            if (demoCompleted['F2'] && demoCompleted['F5']) {
                console.log('Both demo triangles complete. Starting timer...');
                displayReadySetGo();
                // Hide the interaction lock once the entire demo is complete
                if (interactionLock) {
                    interactionLock.style.display = 'none';
                }
            }
        }, { once: true });
    }, { once: true });
}

export function displayReadySetGo() {
    const pieceSpace = document.getElementById('piece-space'); // Change to piece-space
    const messages = ['READY', 'SET', 'GO!'];
    const beepShort = document.getElementById('beep-short');
    const beepLong = document.getElementById('beep-long');
    const completeButton = document.getElementById('complete-btn'); // Get the button element

    let index = 0;

    // Set initial volumes
    beepShort.volume = 0.1;
    beepLong.volume = 0.1;

    // Hide the "I GIVE UP!" button initially
    if (completeButton) {
        completeButton.style.display = 'none';
    }

    // Create the message element and add it to the piece-space container
    const messageElement = document.createElement('div');
    messageElement.classList.add('ready-set-go-message');
    pieceSpace.appendChild(messageElement); // Append to pieceSpace

    function showMessage() {
        if (index < messages.length) {
            messageElement.textContent = messages[index];
            messageElement.style.opacity = '1';

            // Play the appropriate beep sound
            if (index < 2) {
                beepShort.play();
            } else {
                beepLong.play();
            }

            setTimeout(() => {
                messageElement.style.opacity = '0';
                index++;
                setTimeout(showMessage, 500);
            }, 1000);
        } else {
            // Remove the message element and start the timer
            pieceSpace.removeChild(messageElement); // Remove from pieceSpace
            startTimer();

            // Show the "I GIVE UP!" button
            if (completeButton) {
                completeButton.style.display = 'flex';
            }
        }
    }

    showMessage();
}