// ShareScore.js

function sharePuzzleScore(difficulty, timeTaken) {

    // Construct the message with the logo URL, puzzle difficulty, and time
    const message = `🏆 I completed the PIQUE 🧩 in ${timeTaken} on ${difficulty} difficulty!`;
    // Create the SMS link with the prefilled message
    const smsLink = `sms:?body=${encodeURIComponent(message)}`;

    // Open the SMS app using the link
    window.location.href = smsLink;
}

// Export the function for use in other parts of your web app
export { sharePuzzleScore };