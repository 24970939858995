import { calculatePrimaryVertexPosition } from './UI.js';
import { triangleSnapVertices, vertexPositions, createNextTriangleInPieceSpace } from './TrianglePuzzle.js';

export function setupDropListeners() {
    const puzzleContainer = document.getElementById('puzzle-container');

    // Allow dragging over the puzzle container
    puzzleContainer.addEventListener('dragover', (event) => {
        event.preventDefault();  // Necessary to allow the drop
    });

    // Handle drop events for mouse and touch
    puzzleContainer.addEventListener('drop', (event) => {
        event.preventDefault();
        const triangleId = event.dataTransfer.getData('text');  // Get the ID of the dragged triangle
        handleDrop(triangleId, event);  // Handle the drop and snap logic
    });

    // Handle touch end event for touch devices
    puzzleContainer.addEventListener('touchend', (event) => {
        const touch = event.changedTouches[0];
        const targetElement = document.elementFromPoint(touch.clientX, touch.clientY);
        const triangleId = targetElement && targetElement.classList.contains('triangle') ? targetElement.id : null;

        if (triangleId) {
            handleDrop(triangleId, event, true);  // Handle drop and snap logic for touch
        }
    });
}
let isDropping = false;

const finalOffsets = {

    'E1': { x: 1.25, y: 0 },
    'E3': { x: 1.0, y: -0.25 },
    'E4': { x: 0.0, y: -0.25 },
    'E6': { x: -0.5, y: 0.0 },
    'D2': { x: 1.5, y: 0.0 },
    'D5': { x: -0.5, y: -0.25 },
    'C2': { x: 1.5, y: 0.0 },
    'C5': { x: 0.5, y: 0.25 },
    'B1': { x: 1.5, y: 0.75 },
    'B3': { x: 1.25, y: 1.0 },
    'B4': { x: 0.25, y: 1.0 },
    'B6': { x: -1.0, y: 0.25 },
    'A2': { x: 0.5, y: 1.5 },
    'A5': { x: 0.5, y: 1.5 },
};


export function handleDrop(triangleId, event, isTouch = false) {
    if (isDropping) {
        console.log('Drop is already in progress, ignoring duplicate drop.');
        return;  // Ignore if drop is already in progress
    }

    isDropping = true;

    const puzzleContainer = document.getElementById('puzzle-container');
    const triangle = document.getElementById(triangleId);
    const snapSound = document.getElementById('snap-sound');
    console.log(`handleDrop triggered for triangle ${triangleId}`);

    let mouseX, mouseY;
    if (isTouch) {
        const touch = event.changedTouches[0];
        mouseX = touch.clientX - puzzleContainer.getBoundingClientRect().left;
        mouseY = touch.clientY - puzzleContainer.getBoundingClientRect().top;
    } else {
        mouseX = event.clientX - puzzleContainer.getBoundingClientRect().left;
        mouseY = event.clientY - puzzleContainer.getBoundingClientRect().top;
    }

    triangle.style.left = `${mouseX - triangle.offsetWidth / 2}px`;
    triangle.style.top = `${mouseY - triangle.offsetHeight / 2}px`;

    const { x: primaryVertexX, y: primaryVertexY } = calculatePrimaryVertexPosition(triangle, triangle.offsetWidth, triangle.offsetHeight);

    const snapData = triangleSnapVertices[triangleId];
    const targetVertex = vertexPositions[snapData.vertex];
    const currentRotation = parseInt(triangle.style.transform.replace(/[^0-9]/g, '')) % 360;
    const expectedRotation = snapData.rotation;

    const snapOffsetX = snapData.snapOffsetX;
    const snapOffsetY = snapData.snapOffsetY;

    // Adjust target vertex by the snap offsets
    const adjustedTargetX = targetVertex.x + snapOffsetX;
    const adjustedTargetY = targetVertex.y + snapOffsetY;

    // Adjust proximity calculation with the offsetted target vertex
    const proximityX = Math.abs(primaryVertexX - adjustedTargetX);
    const proximityY = Math.abs(primaryVertexY - adjustedTargetY);
    const proximityThreshold = Math.min(puzzleContainer.offsetWidth, puzzleContainer.offsetHeight) * 0.1;
    const isInProximity = proximityX < proximityThreshold && proximityY < proximityThreshold;
    const isRotationCorrect = currentRotation === expectedRotation;

    if (isInProximity && isRotationCorrect) {
        console.log('Snapping triangle into place');

        let xOffset = 0;
        let yOffset = 0;

        switch (currentRotation) {
            case 90:
                xOffset = -triangle.offsetHeight / 2;
                yOffset = 0;
                break;
            case 270:
                xOffset = triangle.offsetHeight / 2;
                yOffset = 0;
                break;
            case 180:
                xOffset = 0;
                yOffset = -triangle.offsetHeight / 2;
                break;
            case 0:
                xOffset = 0;
                yOffset = triangle.offsetHeight / 2;
                break;
            default:
                xOffset = 0;
                yOffset = 0;
                break;
        }

        let newLeft = adjustedTargetX - triangle.offsetWidth / 2 + xOffset;
        let newTop = adjustedTargetY - triangle.offsetHeight / 2 + yOffset;

        // Apply custom offsets for precise positioning
        if (finalOffsets[triangleId]) {
            newLeft += finalOffsets[triangleId].x || 0;
            newTop += finalOffsets[triangleId].y || 0;
        }

        const maxLeft = puzzleContainer.offsetWidth - (currentRotation === 90 || currentRotation === 270 ? triangle.offsetHeight : triangle.offsetWidth);
        const maxTop = puzzleContainer.offsetHeight - triangle.offsetHeight;

        if (currentRotation === 90 || currentRotation === 270) {
            if (newLeft < -triangle.offsetHeight / 2) newLeft = -triangle.offsetHeight / 2;
            if (newLeft > maxLeft + triangle.offsetHeight / 2) newLeft = maxLeft + triangle.offsetHeight / 2;
        } else {
            if (newLeft < 0) newLeft = 0;
            if (newLeft > maxLeft) newLeft = maxLeft;
        }

        if (newTop < 0) newTop = 0;
        if (newTop > maxTop) newTop = maxTop;

        triangle.style.left = `${newLeft}px`;
        triangle.style.top = `${newTop}px`;

        triangle.draggable = false;
        triangle.style.pointerEvents = 'none';

        snapSound.play();

        triangle.classList.add('glow');
        setTimeout(() => triangle.classList.remove('glow'), 1000);

        createNextTriangleInPieceSpace();
    } else {
        console.log('No snapping occurred.');
    }

    setTimeout(() => {
        isDropping = false;
    }, 200);

    puzzleContainer.appendChild(triangle);
}