import { getImagesFromIndexedDB, saveImageToIndexedDB } from './DBUtils.js';
//import { initializeGame } from './TrianglePuzzle.js';
//import { initializeCircleGame} from './CirclePuzzle.js';
import { initializeMenu } from './menu.js';

export async function unscrambleImage(gridSize = 10) {
    getImagesFromIndexedDB('images', null, (images) => {
        const imageRecord = images.find((img) => img.id === 'originalImage');

        if (!imageRecord || !imageRecord.originalImageSrc) {
            console.error('Failed to retrieve the original image from IndexedDB');
            return;
        }

        const img = new Image();
        img.src = imageRecord.originalImageSrc;

        img.onload = function () {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            // Enable image smoothing
            context.imageSmoothingEnabled = true;
            context.imageSmoothingQuality = 'high';

            const innerImageWidth = img.width * 0.909;
            const innerImageHeight = innerImageWidth;
            // Set canvas size to match the image dimensions
            canvas.width = innerImageWidth;
            canvas.height =innerImageHeight;

            // Polaroid inner image adjustment
            const leftBorder = ((img.width - innerImageWidth) / 2);
            const topBorder = ((img.height - innerImageHeight) / 2) - (img.height * 0.0770);

            const squareSize = innerImageWidth / gridSize;
            const offset = -0.5;

            // Known scramble pattern
            const scramblePattern = [
                7, 3, 25, 76, 89, 32, 54, 67, 12, 41,
                23, 56, 99, 15, 2, 81, 45, 37, 61, 18,
                10, 74, 5, 44, 27, 0, 48, 69, 22, 91,
                16, 83, 65, 33, 92, 26, 79, 40, 53, 6,
                35, 98, 1, 46, 31, 87, 17, 20, 90, 59,
                11, 43, 21, 57, 30, 84, 66, 13, 8, 42,
                36, 85, 62, 70, 28, 94, 4, 50, 24, 77,
                19, 47, 58, 68, 29, 63, 60, 38, 75, 95,
                39, 80, 14, 9, 86, 52, 64, 49, 55, 88,
                34, 82, 93, 78, 73, 97, 72, 71, 51, 96
            ];

            // Reverse the scramble pattern
            scramblePattern.forEach((scrambledIndex, originalIndex) => {
                const scrambledX = (scrambledIndex % gridSize) * squareSize + leftBorder - offset;
                const scrambledY = Math.floor(scrambledIndex / gridSize) * squareSize + topBorder - offset;

                const originalX = (originalIndex % gridSize) * squareSize;
                const originalY = Math.floor(originalIndex / gridSize) * squareSize;

                context.drawImage(
                    img,
                    scrambledX, scrambledY, squareSize + offset, squareSize + offset,
                    originalX, originalY, squareSize + 0.75, squareSize + 0.75
                );
            });

// Convert unscrambled canvas content to an image URL
const unscrambledImageURL = canvas.toDataURL();
const unscrambledImageElement = document.createElement('img');
unscrambledImageElement.src = unscrambledImageURL;
unscrambledImageElement.id = 'unscrambled-image';

// Save the unscrambled image to IndexedDB
saveImageToIndexedDB('images', { id: 'unscrambledImage', originalImageSrc: unscrambledImageURL })
.then(() => {
    console.log("Unscrambled image saved to IndexedDB.");
    
    // Call initializeMenu instead of creating buttons directly
    initializeMenu(unscrambledImageURL);
})
.catch(error => {
    console.error("Error saving unscrambled image:", error);
});
};

img.onerror = () => console.error('Failed to load image for unscrambling');
});
}