import { handleDrop } from './DropUtility.js';

// Detect if the browser is Safari
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export function setupDragListeners() {
    document.querySelectorAll('.triangle').forEach(triangle => {
        // Ensure F2 and F5 are not draggable or interactive in any way
        if (triangle.id === 'F2' || triangle.id === 'F5') {
        return;  // Skip adding any listeners for F2 and F5
        }

        let isFirstTouch = true;
        let originalLeft, originalTop;

        // Mouse drag functionality
        triangle.addEventListener('dragstart', (event) => {
            const triangleId = event.target.id;
            event.dataTransfer.setData('text/plain', triangleId);
            event.dataTransfer.effectAllowed = 'move';

            const currentRotation = parseInt(event.target.style.transform.replace(/[^0-9]/g, '')) || 0;

            // Recreate the ghost container (drag image) on each drag event
            const dragContainer = document.createElement('div');
            dragContainer.style.position = 'absolute';
            dragContainer.style.left = '-9999px'; // Move off-screen to hide the container

            if (isSafari) {
                const baseWidth = event.target.offsetWidth;
                const baseHeight = event.target.offsetHeight;
                if (currentRotation === 90 || currentRotation === 270) {
                    dragContainer.style.width = `${baseHeight}px`;
                    dragContainer.style.height = `${baseWidth}px`;
                } else {
                    dragContainer.style.width = `${baseWidth}px`;
                    dragContainer.style.height = `${baseHeight}px`;
                }
                //dragContainer.style.border = '1px solid red'; // Red border for Safari
            } else {
                if (currentRotation === 90 || currentRotation === 270) {
                    dragContainer.style.width = `${event.target.offsetHeight}px`;
                    dragContainer.style.height = `${event.target.offsetWidth}px`;
                } else {
                    dragContainer.style.width = `${event.target.offsetWidth}px`;
                    dragContainer.style.height = `${event.target.offsetHeight}px`;
                }
                //dragContainer.style.border = '1px solid green';  // Green border for non-Safari
            }

            dragContainer.style.backgroundColor = 'rgba(0, 0, 0, 0)';

            // Clone the triangle and set its rotation to match the original triangle
            const dragImage = event.target.cloneNode(true);
            dragImage.style.position = 'absolute';
            dragImage.style.left = '0px';
            dragImage.style.top = '0px';

            if (!isSafari) {
                dragImage.style.transform = `rotate(${currentRotation}deg)`;

                // Adjust the position of the drag image based on the current rotation
                switch (currentRotation) {
                    case 90:
                        dragImage.style.left = `-${event.target.offsetHeight / 2}px`;
                        dragImage.style.top = `${event.target.offsetHeight / 2}px`;
                        break;
                    case 270:
                        dragImage.style.left = `-${event.target.offsetHeight / 2}px`;
                        dragImage.style.top = `${event.target.offsetHeight / 2}px`;
                        break;
                    default:
                        dragImage.style.left = '0px';
                        dragImage.style.top = '0px';
                        break;
                }
            } else if (currentRotation === 90 || currentRotation === 270) {
                dragImage.style.top = `${event.target.offsetHeight / 2}px`;
                dragImage.style.left = `-${event.target.offsetHeight / 2}px`;
            }

            dragContainer.appendChild(dragImage);
            document.body.appendChild(dragContainer);

            // Adjust the drag image's center to match triangle rotation
            let triangleCenterX, triangleCenterY;

            if (!isSafari && (currentRotation === 90 || currentRotation === 270)) {
                triangleCenterX = dragContainer.offsetWidth / 2;
                triangleCenterY = dragContainer.offsetHeight / 2;
            } else {
                triangleCenterX = dragContainer.offsetWidth / 2;
                triangleCenterY = dragContainer.offsetHeight / 2;
            }

            event.dataTransfer.setDragImage(dragContainer, triangleCenterX, triangleCenterY);

            // Remove the container after the drag starts
            setTimeout(() => document.body.removeChild(dragContainer), 0);
        });

        triangle.addEventListener('dragend', () => {
        });

        // Touch start functionality (non-passive)
        triangle.addEventListener('touchstart', (event) => {
            event.preventDefault();
            const touch = event.touches[0];

            triangle.style.zIndex = '1000';

            const puzzleContainer = document.getElementById('puzzle-container');
            const puzzleRect = puzzleContainer.getBoundingClientRect();

            const triangleRect = triangle.getBoundingClientRect();
            
            originalLeft = triangle.style.left;
            originalTop = triangle.style.top;

            if (isFirstTouch) {
                triangle.dataset.touchOffsetX = touch.clientX - triangleRect.left;
                triangle.dataset.touchOffsetY = touch.clientY - triangleRect.top - puzzleRect.top;
                isFirstTouch = false;
            } else {
                triangle.dataset.touchOffsetX = touch.clientX - triangleRect.left;
                triangle.dataset.touchOffsetY = touch.clientY - triangleRect.top;
            }

        }, { passive: false });

        triangle.addEventListener('touchmove', (event) => {
            event.preventDefault();
            const touch = event.touches[0];
            const triangle = event.target;
            const puzzleContainer = document.getElementById('puzzle-container');
            const puzzleRect = puzzleContainer.getBoundingClientRect();
        
            triangle.style.zIndex = '1000';
        
            const offsetX = parseFloat(triangle.dataset.touchOffsetX);
            const offsetY = parseFloat(triangle.dataset.touchOffsetY);
        
            const currentRotation = parseInt(triangle.style.transform.replace(/[^0-9]/g, '')) || 0;
        
            let newLeft = touch.clientX - offsetX - puzzleRect.left;
            let newTop = touch.clientY - offsetY - puzzleRect.top;
        
            // Adjust for rotation-specific offsets
            switch (currentRotation) {
                case 90:
                    newLeft -= triangle.offsetHeight / 2;
                    newTop += triangle.offsetWidth / 4;
                    break;
                case 270:
                    newLeft -= triangle.offsetHeight / 2;
                    newTop += triangle.offsetWidth / 4;
                    break;
                default:
                    break;
            }
        
            // Calculate maximum allowed values for left, right, and bottom
            let maxLeft, maxRight, maxBottom;
        
            if (currentRotation === 90 || currentRotation === 270) {
                // For 90 and 270 degrees, swap width and height
                maxLeft = -triangle.offsetWidth / 4; // Allow closer movement to the left
                maxRight = puzzleRect.width - triangle.offsetHeight - triangle.offsetWidth / 4;
                maxBottom = puzzleRect.height - triangle.offsetWidth + triangle.offsetHeight / 2; // Allow closer movement to the bottom
            } else {
                // For 0 and 180 degrees, use normal width and height
                maxLeft = 0;
                maxRight = puzzleRect.width - triangle.offsetWidth;
                maxBottom = puzzleRect.height - triangle.offsetHeight;
            }
        
            // Enforce boundaries
            if (newLeft < maxLeft) newLeft = maxLeft;
            if (newLeft > maxRight) newLeft = maxRight;
            if (newTop < 0) newTop = 0;
            if (newTop > maxBottom) newTop = maxBottom;
        
            // Apply the calculated positions
            triangle.style.left = `${newLeft}px`;
            triangle.style.top = `${newTop}px`;
        }, { passive: false });

// Touch end functionality
triangle.addEventListener('touchend', (event) => {
    const triangle = event.target;
    const puzzleContainer = document.getElementById('puzzle-container');
    const puzzleRect = puzzleContainer.getBoundingClientRect();
    const triangleRect = triangle.getBoundingClientRect();

    // Check if the triangle is outside the puzzle container boundaries
    const isOutside = (
        triangleRect.left < puzzleRect.left ||
        triangleRect.right > puzzleRect.right ||
        triangleRect.top < puzzleRect.top ||
        triangleRect.bottom > puzzleRect.bottom
    );

    if (isOutside) {
        // Reposition the triangle back inside the puzzle container
        const maxLeft = puzzleRect.width - triangle.offsetWidth;
        const maxTop = puzzleRect.height - triangle.offsetHeight;

        let newLeft = parseFloat(triangle.style.left) || 0;
        let newTop = parseFloat(triangle.style.top) || 0;

        if (newLeft < 0) newLeft = 0;
        if (newLeft > maxLeft) newLeft = maxLeft;
        if (newTop < 0) newTop = 0;
        if (newTop > maxTop) newTop = maxTop;

        triangle.style.left = `${newLeft}px`;
        triangle.style.top = `${newTop}px`;
    }

    const touch = event.changedTouches[0];
    const adjustedX = touch.clientX - puzzleRect.left;
    const adjustedY = touch.clientY - puzzleRect.top;

    const triangleId = triangle.id;
    if (triangleId) {
        handleDrop(triangleId, event, true);
    }

    triangle.style.zIndex = '';
});
    });
}