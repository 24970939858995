import { vertexPositions, triangleSnapVertices } from './TrianglePuzzle.js';
import { saveImageToIndexedDB } from './DBUtils.js';

export const triangleImages = {};  // Temporary storage for triangle images

export function splitImageIntoTriangles(containerWidth, containerHeight, unscrambledImageURL) {
    return new Promise(async (resolve, reject) => {
        console.log("Starting the process of splitting and saving image into triangles...");

        if (!(unscrambledImageURL instanceof Image)) {
            console.error("unscrambledImage is not an instance of Image.");
            return;
        }

        // Adjust container dimensions to the nearest even number
        const adjustedWidth = containerWidth % 2 === 0 ? containerWidth : containerWidth - 1;
        const adjustedHeight = containerHeight % 2 === 0 ? containerHeight : containerHeight - 1;

        const imageWidth = unscrambledImageURL.naturalWidth;
        const innerImageWidth = imageWidth * 1;
        const innerImageHeight = innerImageWidth;
        const leftBorder = (imageWidth - innerImageWidth) / 2;
        const topBorder = (imageWidth - innerImageHeight) / 2;

        const triangleCanvas = document.createElement('canvas');
        triangleCanvas.width = adjustedWidth;
        triangleCanvas.height = adjustedHeight;
        const triangleCtx = triangleCanvas.getContext('2d');

        if (!triangleCtx) {
            console.error("Triangle canvas context not found.");
            reject("Canvas context not found");
            return;
        }

        // Draw the full image onto the canvas
        triangleCtx.drawImage(
            unscrambledImageURL,
            leftBorder, topBorder, unscrambledImageURL.naturalWidth, unscrambledImageURL.naturalHeight,
            0, 0, triangleCanvas.width, triangleCanvas.height
        );

        // Create and save triangles directly from the image
        await createTrianglesFromImage(triangleCanvas);

        console.log("All triangles created and saved.");
        resolve();  // Only resolve after all triangles are confirmed saved
    });
}

async function createTrianglesFromImage(triangleCanvas) {
    const triangleWidth = triangleCanvas.width / 2;
    const triangleHeight = triangleWidth / 2;

    for (const triangleId of Object.keys(triangleSnapVertices)) {
        const snapData = triangleSnapVertices[triangleId];
        const vertex = vertexPositions[snapData.vertex];

        if (!vertex) {
            console.error(`Vertex for ${snapData.vertex} is undefined for triangle ${triangleId}`);
            continue;
        }

        const rotation = snapData.rotation;
        const vertices = calculateVertices(vertex.x, vertex.y, triangleWidth, triangleHeight, rotation);

        const triangleCanvasCopy = document.createElement('canvas');
        triangleCanvasCopy.width = triangleCanvas.width;
        triangleCanvasCopy.height = triangleCanvas.height;
        const triangleCtxCopy = triangleCanvasCopy.getContext('2d');

        triangleCtxCopy.imageSmoothingEnabled = true;
        triangleCtxCopy.save();
        triangleCtxCopy.beginPath();
        triangleCtxCopy.moveTo(vertices.x1, vertices.y1);
        triangleCtxCopy.lineTo(vertices.x2, vertices.y2);
        triangleCtxCopy.lineTo(vertices.x3, vertices.y3);
        triangleCtxCopy.closePath();
        triangleCtxCopy.clip();

        triangleCtxCopy.drawImage(triangleCanvas, 0, 0, triangleCanvas.width, triangleCanvas.height);
        triangleCtxCopy.restore();

        const croppedCanvas = cropTriangleFromCanvas(triangleCanvasCopy, vertices);
        const imageData = croppedCanvas.toDataURL();

        triangleImages[triangleId] = imageData;

        // Await each save operation individually to ensure sequence
        await saveImageToIndexedDB('triangleSlices', { id: triangleId, data: imageData }, triangleId);
        console.log(`Saved image for triangle ${triangleId}`);
    }
}

function cropTriangleFromCanvas(triangleCanvasCopy, vertices) {
    const minX = Math.min(vertices.x1, vertices.x2, vertices.x3);
    const maxX = Math.max(vertices.x1, vertices.x2, vertices.x3);
    const minY = Math.min(vertices.y1, vertices.y2, vertices.y3);
    const maxY = Math.max(vertices.y1, vertices.y2, vertices.y3);

    const croppedCanvas = document.createElement('canvas');
    croppedCanvas.width = Math.ceil(maxX - minX);
    croppedCanvas.height = Math.ceil(maxY - minY);
    const croppedCtx = croppedCanvas.getContext('2d');

    croppedCtx.imageSmoothingEnabled = false;
    croppedCtx.drawImage(
        triangleCanvasCopy,
        minX, minY, maxX - minX, maxY - minY,
        0, 0, croppedCanvas.width, croppedCanvas.height
    );

    // Draw a purple border around the triangle
    croppedCtx.strokeStyle = 'purple'; // Set the border color
    croppedCtx.lineWidth = 2; // Set the border thickness
    croppedCtx.beginPath();
    croppedCtx.moveTo(vertices.x1 - minX, vertices.y1 - minY);
    croppedCtx.lineTo(vertices.x2 - minX, vertices.y2 - minY);
    croppedCtx.lineTo(vertices.x3 - minX, vertices.y3 - minY);
    croppedCtx.closePath();
    croppedCtx.stroke(); // Draw the border


    return croppedCanvas;
}

function calculateVertices(xOffset, yOffset, triangleWidth, triangleHeight, rotation) {
    let x1, y1, x2, y2, x3, y3;

    switch (rotation) {
        case 0:
            x1 = xOffset;
            y1 = yOffset;
            x2 = xOffset - triangleWidth / 2;
            y2 = yOffset + triangleHeight;
            x3 = xOffset + triangleWidth / 2;
            y3 = yOffset + triangleHeight;
            break;
        case 90:
            x1 = xOffset;
            y1 = yOffset;
            x2 = xOffset - triangleHeight;
            y2 = yOffset - triangleWidth / 2;
            x3 = xOffset - triangleHeight;
            y3 = yOffset + triangleWidth / 2;
            break;
        case 180:
            x1 = xOffset;
            y1 = yOffset;
            x2 = xOffset + triangleWidth / 2;
            y2 = yOffset - triangleHeight;
            x3 = xOffset - triangleWidth / 2;
            y3 = yOffset - triangleHeight;
            break;
        case 270:
            x1 = xOffset;
            y1 = yOffset;
            x2 = xOffset + triangleHeight;
            y2 = yOffset + triangleWidth / 2;
            x3 = xOffset + triangleHeight;
            y3 = yOffset - triangleWidth / 2;
            break;
        default:
            console.error(`Invalid rotation: ${rotation}`);
            break;
    }

    return { x1, y1, x2, y2, x3, y3 };
}