import { setEvenDimensions } from './scripts/landing.js';
import { setupLandingPage } from './scripts/landing.js';
import { clearIndexedDB } from './scripts/DBUtils.js';
import { completePuzzleManually } from './scripts/PuzzleCompletion.js';
import { stopTimer } from './scripts/Timer.js';

document.addEventListener("DOMContentLoaded", () => {
    // Your app's custom URL scheme or Universal Link
    const appURL = "piquepicapp://";

    const now = Date.now();

    // Try to open the app
    window.location.href = appURL;

    // Fallback logic to continue with the website
    setTimeout(() => {
        if (Date.now() - now < 2000) {
            console.log("App not found. Continuing with the website...");
            // No action is taken; the webpage continues as is
        } else {
            const userConfirmed = confirm("We noticed you have the PiquePic app installed. Would you like to switch to the app?");
            if (userConfirmed) {
                window.location.href = appURL; // Redirect to the app
            } else {
                console.log("User chose to stay on the website.");
            }
        }
    }, 1500);
});

document.addEventListener("DOMContentLoaded", () => {
    // Set up the environment
    clearSessionAndDB();
    setupGlobalEventListeners();
    setupLandingPage(); // Set up landing page interactions
    setupCompleteButton();
});

function clearSessionAndDB() {
    if (typeof sessionStorage !== 'undefined') {
        sessionStorage.clear();
    } else {
        console.warn('Session storage is not supported in this browser or mode.');
    }
    clearIndexedDB('images');
}

function setupGlobalEventListeners() {
    // Set up global event listeners for the application
    window.addEventListener("resize", () => {
        if (!appLocked) {
            setEvenDimensions("puzzle-container");
        }
    });
    setupVolumeControl();
}

function setupVolumeControl() {
    const volumeIcon = document.getElementById('volume-icon');
    if (!volumeIcon) return;

    const sounds = {
        swirlSound: document.getElementById('swirl-sound'),
        dragSound: document.getElementById('drag-sound'),
        snapSound: document.getElementById('snap-sound'),
        completeSound: document.getElementById('complete-sound'),
        beepShort: document.getElementById('beep-short'),
        beepLong: document.getElementById('beep-long'),
        gameOver: document.getElementById('game-over')
    };

    let isMuted = false;
    volumeIcon.addEventListener('click', () => {
        isMuted = !isMuted;
        Object.values(sounds).forEach(sound => {
            if (sound) sound.muted = isMuted;
        });
        volumeIcon.src = isMuted ? './icons/volume-off.svg' : './icons/volume-up.svg';
    });
}

function setupCompleteButton() {
    const completeBtn = document.getElementById('complete-btn');
    const blingSound = document.getElementById('snap-sound');

    if (completeBtn) {
        completeBtn.innerText = 'I GIVE UP!';
        completeBtn.addEventListener('click', () => {
            if (blingSound) {
                blingSound.play().catch((error) => {
                    console.error('Error playing bling sound:', error);
                });
            }

            const sounds = {
                gameOver: document.getElementById('game-over')
            };
            let isMuted = false;

            if (!isMuted && sounds.gameOver) {
                sounds.gameOver.play();
            }

            stopTimer(true); // Stop the timer

            const puzzleContainer = document.getElementById('puzzle-container');
            if (puzzleContainer) {
                while (puzzleContainer.firstChild) {
                    puzzleContainer.removeChild(puzzleContainer.firstChild);
                }
            }

            completePuzzleManually();
            completeBtn.remove();
        });
    } else {
        console.error('Complete Puzzle button not found');
    }
}