//import { initializeGame, applyTriangleImages } from './scripts/TrianglePuzzle.js';
import { setEvenDimensions } from './scripts/landing.js';
import { setupLandingPage } from './scripts/landing.js';
import { clearIndexedDB } from './scripts/DBUtils.js';
import { completePuzzleManually } from './scripts/PuzzleCompletion.js';
import { stopTimer } from './scripts/Timer.js';

// Check if the Screen Orientation API is supported
if (screen.orientation) {
    // Lock the orientation to portrait mode
    screen.orientation.lock('portrait').catch((error) => {
      console.error('Failed to lock screen orientation:', error);
    });
  
    // Listen for orientation changes
    screen.orientation.addEventListener('change', () => {
      if (screen.orientation.type.startsWith('landscape')) {
        alert('Please rotate your device to portrait mode.');
      }
    });
  } else {
    console.warn('Screen Orientation API is not supported on this device.');
  }


document.addEventListener("DOMContentLoaded", () => {
    // Set up the environment
    clearSessionAndDB();
    setupGlobalEventListeners();
    setupLandingPage(); // Set up landing page interactions
});

function clearSessionAndDB() {
    if (typeof sessionStorage !== 'undefined') {
        sessionStorage.clear();
    } else {
        console.warn('Session storage is not supported in this browser or mode.');
    }
    clearIndexedDB('images');
}

function setupGlobalEventListeners() {
    // Set up global event listeners for the application
    window.addEventListener("resize", () => setEvenDimensions("puzzle-container"));
    setupVolumeControl();
}

function setupVolumeControl() {
    const volumeIcon = document.getElementById('volume-icon');
    if (!volumeIcon) return;

    const sounds = {
        swirlSound: document.getElementById('swirl-sound'),
        dragSound: document.getElementById('drag-sound'),
        snapSound: document.getElementById('snap-sound'),
        completeSound: document.getElementById('complete-sound'),
        beepShort: document.getElementById('beep-short'),
        beepLong: document.getElementById('beep-long'),
        gameOver: document.getElementById('game-over')
    };

    let isMuted = false;
    volumeIcon.addEventListener('click', () => {
        isMuted = !isMuted;
        Object.values(sounds).forEach(sound => {
            if (sound) sound.muted = isMuted;
        });
        volumeIcon.src = isMuted ? './icons/volume-off.svg' : './icons/volume-up.svg';
    });
}

// Function to set up the "Complete Puzzle" button
function setupCompleteButton() {
    const completeBtn = document.getElementById('complete-btn');
    const blingSound = document.getElementById('snap-sound');

    if (completeBtn) {
        completeBtn.innerText = 'I GIVE UP!';
        completeBtn.addEventListener('click', () => {
            // Play the bling sound when the button is clicked
            if (blingSound) {
                blingSound.play().catch((error) => {
                    console.error('Error playing bling sound:', error);
                });
            }
            
            const sounds = {
                gameOver: document.getElementById('game-over')
            };
            let isMuted = false;

            // Check if sounds should be muted
            if (!isMuted && sounds.gameOver) {
                sounds.gameOver.play();
            }

            // Call your timer stop/reset function
            stopTimer(true); // Assuming `stopTimer` is defined elsewhere

            const puzzleContainer = document.getElementById('puzzle-container');
            if (puzzleContainer) {
                while (puzzleContainer.firstChild) {
                    puzzleContainer.removeChild(puzzleContainer.firstChild);
                }
            }

            // Manually complete the puzzle
            completePuzzleManually(); // Assuming `completePuzzleManually` is defined elsewhere

            // Remove the button to prevent multiple clicks
            completeBtn.remove();
        });
    } else {
        console.error('Complete Puzzle button not found');
    }
}

// Call the function to set up the "Complete Puzzle" button
setupCompleteButton();