import { handlePuzzleComplete } from "./PuzzleCompletion";

export function setupRingDragListeners() {
    const blingSound = document.getElementById('snap-sound');
    document.querySelectorAll('.ring').forEach((ring, index) => {
        console.log(`Setting up listeners for ring ${index}`);

        let startX, startY;

        ring.draggable = false; // Prevent default drag behavior

        // Add rotation on mouse click (left/right for clockwise/counterclockwise)
        ring.addEventListener('mousedown', (event) => {
            event.preventDefault();
            if (event.button === 0) {
                rotateRing(ring, 10);
                playSound(blingSound);
            } else if (event.button === 2) {
                rotateRing(ring, -10);
                playSound(blingSound);
            }
        });

        // Touch swipe detection for rotation
        ring.addEventListener('touchstart', (event) => {
            event.preventDefault();
            const touch = event.touches[0];
            startX = touch.clientX;
            startY = touch.clientY;
        });

        ring.addEventListener('touchend', (event) => {
            event.preventDefault();
            const touch = event.changedTouches[0];
            const endX = touch.clientX;
            const endY = touch.clientY;

            // Calculate swipe direction and apply rotation
            const dx = endX - startX;
            const dy = endY - startY;
            const angle = Math.atan2(dy, dx) * (180 / Math.PI);
            const distance = Math.sqrt(dx * dx + dy * dy);

            if (distance > 20) {
                const clockwise = isClockwiseSwipe(ring, angle, startX, startY);
                rotateRing(ring, clockwise ? 10 : -10);
                playSound(blingSound);
            }
        });
    });
}

// Helper functions (rotateRing and isClockwiseSwipe) remain the same as in your code.

function isClockwiseSwipe(ring, angle, startX, startY) {
    // Calculate the center of the ring relative to the viewport
    const ringRect = ring.getBoundingClientRect();
    const centerX = ringRect.left + ringRect.width / 2;
    const centerY = ringRect.top + ringRect.height / 2;

    // Determine the quadrant based on the initial touch position
    const isTop = startY < centerY;
    const isRight = startX > centerX;

    // Define clockwise conditions for each quadrant with clear boundaries
    if (isTop && isRight) { 
        // Top-Right Quadrant: Rightward or downward swipe (10 to 80 degrees)
        return angle >= 0 && angle <= 150;
    } else if (isTop && !isRight) { 
        // Top-Left Quadrant: Downward or leftward swipe (-80 to -10 degrees)
        return angle >= -80 && angle <= -10;
    } else if (!isTop && isRight) { 
        // Bottom-Right Quadrant: Upward or leftward swipe (100 to 170 degrees)
        return angle >= 90 && angle <= 180;
    } else { 
        // Bottom-Left Quadrant: Upward or rightward swipe (-170 to -100 degrees)
        return angle >= -170 && angle <= -100;
    }
}

// Rotation function
function rotateRing(ring, angle) {
    const currentRotation = parseFloat(ring.dataset.currentRotation) || 0;
    const newRotation = (currentRotation + angle) % 360;
    ring.style.transform = `translate(-50%, -50%) rotate(${newRotation}deg)`;
    ring.dataset.currentRotation = newRotation;
    checkPuzzleCompletion();
}

// Function to check if all rings are aligned at 0 degrees
function checkPuzzleCompletion() {
    const allRings = document.querySelectorAll('.ring');
    const isComplete = Array.from(allRings).every(ring => {
        const rotation = parseFloat(ring.dataset.currentRotation) || 0;
        return rotation === 0;
    });

    if (isComplete) {

        // Call the handlePuzzleComplete function
        handlePuzzleComplete();
    }
}

// Function to play the sound
function playSound(sound) {
    if (sound) {
        sound.currentTime = 0; // Reset sound to the beginning
        sound.play();
    }
}
